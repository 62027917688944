<div class="form-control-with-close-icon">
  <input
    [placeholder]="element.placeholder"
    [title]="element.placeholder"
    class="form-control input-text w-100"
    [ngClass]="
      element.itemIndex === 0 ? 'first-text-child' : 'middle-text-child'
    "
    [formControl]="control"
    autocomplete="off"
    maxlength="100"
    [pattern]="element?.onlyNumber ? '[0-9]*' : '.*'"
  />

  <span class="clear-icon" *ngIf="control?.value">
    <i (click)="onResetData()">&times;</i>
  </span>
</div>

<div class="invalid" *ngIf="control?.hasError('pattern')">
  Please enter only numbers.
</div>
