<div class="multiselect-panel">
  <div class="d-flex justify-content-between align-items-center mb-3">
    <a (click)="onSelect(true)" class="text-dark">Select All</a>
    <a (click)="onSelect(false)" class="text-dark">Deselect All</a>
  </div>
  <div class="clearfix"></div>

  <div class="form-control-with-close-icon mb-2">
    <input
      placeholder="Search"
      class="form-control w-100"
      autocomplete="off"
      (input)="setInput($event)"
      [value]="search"
    />

    <span class="clear-icon" *ngIf="search !== ''">
      <i (click)="onResetData()">&times;</i>
    </span>
  </div>

  <ul class="list-group p-0">
    <li
      class="list-group-item border-0 d-flex justify-content-between align-items-center p-0"
      *ngFor="let ms of params | filter : 'name' : search"
      (click)="optionClicked(ms)"
    >
      <div class="form-check">
        <span
          class="form-check-input mt-0 border-0 bi"
          [ngClass]="{
            'bi-square': !ms.isSelected,
            'bi-check2-square': ms.isSelected
          }"
        ></span>
        <label class="form-check-label fw-normal pl-2">{{ ms.name }}</label>
      </div>
    </li>
  </ul>
</div>
