<div class="container-fluid section">
    <div class="col-md-12">
        <div class="top-align">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form">
                            <div class="row">
                                <div class="col-md-12 head">
                                    <h4>About Us</h4>
                                </div>
                                <div class="col-md-6 col-xs-12"  style="background:url(assets/images/company.jpeg); background-size: cover; min-height:250px; ">
                                </div>
                                <div class="col-md-6 col-xs-12">
                                    <h4 class="font-bold fs16">{{data?.title}}</h4>
                                    <p>{{data?.content1}}</p>
                                    <p class="mb20">{{data?.content2}}</p>
                                    <p class="mb20">{{data?.content3}}</p>

                                    <h4 class="font-bold f-16">{{data.jobseekerSubTitle}}</h4>
                                    <p>{{data?.jobSeekerContent1}}</p>
                                    <p class="mb20">{{data?.jobSeekerContent2}}</p>
                                    <p class="mb20">{{data?.jobSeekerContent3}}</p>
                                    <p class="mb20">{{data?.jobSeekerContent4}}</p>

                                    <h4 class="font-bold f-16">{{data.recruiterSubTitle}}</h4>
                                    <p>{{data?.recruiterContent1}}</p>
                                    <p class="mb20">{{data?.recruiterContent2}}</p>
                                    <p class="mb20">{{data?.recruiterContent3}}</p>
                                    <p class="mb20">{{data?.recruiterContent4}}</p>
                                    <p class="mb20">{{data?.recruiterContent5}}</p>
                                    <p class="mb20">{{data?.recruiterContent6}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
