import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import { DBSearch, SortDirection, SortOption } from 'src/app/models/solrSearch';

@Component({
  selector: 'app-pagination-db',
  templateUrl: './pagination-db.component.html',
  styleUrls: ['./pagination-db.component.scss'],
})
export class PaginationDbComponent {
  @Input() templateFromParent: TemplateRef<any>;
  @Input() responseData: any;
  @Input() type: string;
  @Input() pagination: {
    nextMark: string;
    totalRecords: number;
    pageCount: number;
  };
  @Input() dbSearch: DBSearch;
  @Input() sortOptions: SortOption[];
  currentPageCount: number = 0;
  start: number = 1;
  end: number = 0;

  totalViewedRecords: number;
  pageSize: number;

  @Output() searchResultEvent = new EventEmitter<DBSearch>();

  ngOnChanges() {
    this.pageSize = this.dbSearch.pagination.pageSize;
    this.start = this.dbSearch.pagination.startIndex;
    this.end = this.start + this.pageSize - 1;

    if (this.pagination) {
      const { totalRecords } = this.pagination;

      if (this.totalViewedRecords >= totalRecords || totalRecords <= this.pageSize) {
        this.totalViewedRecords = totalRecords;
        this.end = totalRecords;
      }
    }
  }

  ngOnInit() {
    this.totalViewedRecords = this.pageSize;
  }

  getSortIcon(option: SortOption) {
    if (option.sortDirection === SortDirection.None) {
      return '';
    }
    option.isActive = true;
    return option.sortDirection === SortDirection.Ascending
      ? 'fa fa-angle-up'
      : 'fa fa-angle-down';
  }

  onSortBy(option: SortOption) {
    //reset all sort
    this.sortOptions.forEach((opt) => {
      if (opt.displayName !== option.displayName) {
        opt.isActive = false;
        opt.sortDirection = SortDirection.None;
      }
    });

    if (option.sortDirection === '') {
      option.sortDirection = SortDirection.Ascending;
    } else {
      option.sortDirection =
        option.sortDirection === SortDirection.Ascending
          ? SortDirection.Descending
          : SortDirection.Ascending;
    }
    option.isActive = true;

    let sortColumns = option.sortKey.split(',');
    this.dbSearch.pagination.sortDTO = {
      sortColumn: sortColumns,
      sortDirection: option.sortDirection,
    };
    this.currentPageCount = 0;
    this.start = 1;
    this.dbSearch.pagination.startIndex = this.start;
    this.totalViewedRecords = this.pageSize;
    this.searchResultEvent.emit(this.dbSearch);
  }

  onPageSelected($event: Event, b: boolean) {
    this.pageSize = +$event.target['value'];
    this.totalViewedRecords = this.pageSize;
    this.dbSearch.pagination.pageSize = +this.pageSize;
    this.currentPageCount = 0;
    this.start = 1;
    this.updatePayload();
  }

  onShowPreviousPage() {
    if (this.currentPageCount > 0) {
      if (this.totalViewedRecords === this.pagination.totalRecords) {
        this.totalViewedRecords =
          this.currentPageCount * this.dbSearch.pagination.pageSize;
        this.end = this.currentPageCount * this.pageSize;
      } else {
        this.totalViewedRecords =
          this.totalViewedRecords - this.dbSearch.pagination.pageSize;
        this.end = this.end - this.pageSize;
      }
      this.start = this.start - this.pageSize;
      this.currentPageCount = this.currentPageCount - 1;
    } else {
      this.totalViewedRecords = this.dbSearch.pagination.pageSize;
    }
    this.updatePayload();
  }

  onShowNextPage() {
    this.totalViewedRecords =
      this.totalViewedRecords + this.dbSearch.pagination.pageSize;
    this.start = this.start + this.pageSize;
    this.currentPageCount = this.currentPageCount + 1;
    this.end = this.end + this.pageSize;
    this.updatePayload();
  }

  updatePayload() {
    this.dbSearch.pagination.startIndex = this.start;
    this.searchResultEvent.emit(this.dbSearch);
    window.scrollTo(0, 0);
  }
}
