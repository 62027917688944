import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './common/home/home.component';
import { AboutUsComponent } from './common/about-us/about-us.component';
import { ContactUsComponent } from './common/contact-us/contact-us.component';
import { ContactResolverService } from '../services/resolvers/contact-resolver.service';
import { AuthGuard } from '../services/guards/auth-guard.service';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  {
    path: 'login',
    loadChildren: () =>
      import('./authorization/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'verifyEmail',
    loadChildren: () =>
      import('./authorization/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'activateUser/:country',
    loadChildren: () =>
      import('./authorization/set-password/set-password.module').then(
        (m) => m.SetPasswordModule
      ),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./authorization/register/register.module').then(
        (m) => m.RegisterModule
      ),
  },
  {
    path: 'job-search',
    loadChildren: () =>
      import('./jobseeker/job-search/job-search.module').then(
        (m) => m.JobSearchModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'recruiter-search',
    loadChildren: () =>
      import('./jobseeker/recruiter-search/recruiter-search.module').then(
        (m) => m.RecruiterSearchModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'company-search',
    loadChildren: () =>
      import('./jobseeker/company-search/company-search.module').then(
        (m) => m.CompanySearchModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./profile/profile.module').then((m) => m.ProfileModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'search-job',
    loadChildren: () =>
      import('./admin/jobs/job-search-admin/job-search-admin.module').then(
        (m) => m.JobSearchAdminModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'search-profile',
    loadChildren: () =>
      import('./admin/search-profile-admin/search-profile-admin.module').then(
        (m) => m.SearchProfileAdminModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'recruiter',
    loadChildren: () =>
      import('./admin/search-recruiter-admin/search-recruiter-admin.module').then(
        (m) => m.SearchRecruiterAdminModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'company',
    loadChildren: () =>
      import('./admin/company/company.module').then(
        (m) => m.CompanyModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'profile-search',
    loadChildren: () =>
      import('./common/profile-search/profile-search.module').then(
        (m) => m.ProfileSearchModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'freelancer-search',
    loadChildren: () =>
      import('./recruiter/freelancer-search/freelancer-search.module').then(
        (m) => m.FreelancerSearchModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'applied-jobs',
    loadChildren: () =>
      import('./jobseeker/applied-jobs/applied-jobs.module').then(
        (m) => m.AppliedJobsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'applications',
    loadChildren: () =>
      import('./recruiter/applications/applications.module').then(
        (m) => m.ApplicationsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'jobs-posted',
    loadChildren: () =>
      import('./recruiter/job-posted/job-posted.module').then(
        (m) => m.JobPostedModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'job-details',
    loadChildren: () =>
      import('./common/job-details/job-details.module').then(
        (m) => m.JobDetailsModule
      ),
  },
  {
    path: 'hot-list',
    loadChildren: () =>
      import('./common/hotlist/hotlist.module').then((m) => m.HotlistModule),
  },
  {
    path: 'post-jobs',
    loadChildren: () =>
      import('./recruiter/post-new-job/post-new-job.module').then(
        (m) => m.PostNewJobModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'clone-job',
    loadChildren: () =>
      import('./recruiter/post-new-job/post-new-job.module').then(
        (m) => m.PostNewJobModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'pick-list',
    loadChildren: () =>
      import('./common/picklist/picklist.module').then((m) => m.PicklistModule),
  },
  {
    path: 'update-job',
    loadChildren: () =>
      import('./admin/jobs/edit-delete-job/edit-delete-job.module').then(
        (m) => m.EditDeleteJobModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'freelance',
    loadChildren: () =>
      import('./common/freelance/freelance.module').then(
        (m) => m.FreelanceModule
      ),
    canActivate: [AuthGuard],
  },
  { path: 'about-us', component: AboutUsComponent },
  {
    path: 'contact-us',
    component: ContactUsComponent,
    resolve: { data: ContactResolverService },
  },
  {
    path: 'future-jobs',
    loadChildren: () =>
      import('./jobseeker/future-jobs/future-jobs.module').then(
        (m) => m.FutureJobsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'feedback',
    loadChildren: () =>
      import('./recruiter/feedback/feedback.module').then(
        (m) => m.FeedbackModule
      ),
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./common/settings/settings.module').then(
        (m) => m.SettingsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'delete-profile',
    loadChildren: () =>
      import('./common/delete-profile/delete-profile.module').then(
        (m) => m.DeleteProfileModule
      )
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class BodyRoutingModule {}
