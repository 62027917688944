<div class="container-fluid p-0" class="banner">
  <div class="row">
    <div class="col-6 banner-left">
      <div class="title">
        <h1>
          Your Future Starts
          <br />Here Now
        </h1>
        <span>Find your desired job in a minute </span>
      </div>
    </div>
    <div class="col-6 banner-right"></div>
  </div>
</div>
<div class="d-flex justify-content-center">
  <form [formGroup]="searchFormGroup" class="form-inline">
    <div class="search-position">
      <div class="d-flex justify-content-start row g-0 search-ui-width">
        <ng-container #dynamicComponent></ng-container>
        <div class="search-button-width">
          <div class="d-flex-botton-position">
            <button
              #btn
              class="form-control search-button"
              [disabled]="!searchFormGroup.valid"
              (click)="onSearchClick()"
            >
              <span>
                <i
                  class="fa fa-search"
                  aria-hidden="true"
                  [title]="buttonLabel"
                ></i>
              </span>
              <span>
                {{ buttonLabel }}
              </span>
            </button>
            <div class="clear-search mt-2">
              <a (click)="onClearResult()">
                <span> Clear Search </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
