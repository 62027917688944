import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Element } from 'src/app/utils/constants';

@Component({
  selector: 'app-free-text',
  templateUrl: './free-text.component.html',
  styleUrls: ['./free-text.component.scss'],

  host: {
    class: 'col-sm-12 col-md z-index-9 mb-1 mb-md-0',
  },
})
export class FreeTextComponent implements OnInit {
  @Input() element: Element;
  @Input() control: FormControl;

  construcator() {}

  ngOnInit() {}

  onResetData() {
    this.control.reset();
  }
}
