import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OfficeAddress } from 'src/app/models/office-address.nodel';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/localStorage.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { Constants, validateAllFormFields } from 'src/app/utils/constants';
import { CustomValidation } from 'src/app/utils/customvalidation';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent {
  country: string = '';
  addresses: Array<OfficeAddress> = [];
  data: any;
  formSubmit: boolean = false;
  contact: any = {};
  message = "";
  defaultcountycode = "+91";
  countryCodeData: any;
  contactusControls : FormGroup;
  patternValidator = [Validators.required, Validators.maxLength(100)];
  

  constructor(
    public localStorageService: LocalStorageService, 
    private authService: AuthenticationService,
    private toasterService: ToastrService,
    private activatedRoute : ActivatedRoute,
    private spinnerService : SpinnerService,
    private commonService : CommonService
  ) {
    window.scrollTo(0, 0);
    this.authService.userCountry.subscribe(res => {
      if(res) {
        this.country = res;
        if(this.countryCodeData) {
          this.defaultcountycode = this.countryCodeData.find(el=>el.code == this.country).name;
        }
      }
    });
    this.country = this.localStorageService.getData(Constants.COUNTRY);
    this.authService.getAddresses().subscribe((res) => {
      if (res.header.success === true) {
        this.addresses = res.data as Array<OfficeAddress>;
      }
    });
    this.activatedRoute.data.subscribe((countryCodeData: any) =>{
      this.countryCodeData = countryCodeData.data;
      this.defaultcountycode = this.countryCodeData.find(el=>el.code == this.country).name;
    })
    this.getContactForm();
  }

  getContactForm() {
      this.contactusControls = new FormGroup({
      firstName: new FormControl("", [...this.patternValidator, CustomValidation.txtNamePtn]),
      lastName: new FormControl("", [...this.patternValidator, CustomValidation.txtNamePtn]),
      email: new FormControl("", [...this.patternValidator, CustomValidation.emailPtn]),
      phone: new FormControl("", [Validators.required, Validators.minLength(12)]),
      comments: new FormControl("", [Validators.required, Validators.maxLength(500)]),
      recaptchaReactive: new FormControl(null, Validators.required)
    });
  }

  onSubmit(formData: any){
    this.formSubmit = true;
    if (this.contactusControls.valid) {
      this.spinnerService.start();
      const payload = formData.form.value;
      delete payload['recaptchaReactive'];
      this.commonService.contactNow(payload).subscribe((res) => {
        if (res.header.success) {
          this.spinnerService.stop();
          this.toasterService.success(
            "Contact details shared successfully.",
            "Success"
          );
          formData.resetForm();
          this.formSubmit = false;
          this.contactusControls.patchValue({ txtcountycode: this.defaultcountycode });
        } else {
          this.spinnerService.stop();
          this.toasterService.error(
            "Failed to send your contact details. Please try again!",
            "Error"
          );
        }
      },err=>{
        this.spinnerService.stop();
      });
    } else {
      validateAllFormFields(this.contactusControls);
    }
  }

  isCaptchaResolved(){
    return this.formSubmit && this.contactusControls.controls['recaptchaReactive'].invalid;
  }
}
