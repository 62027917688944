<!-- From Date -->
<div class="form-control-with-close-icon">
  <input
    [id]="element.name"
    type="text"
    [formControl]="control"
    [(bsValue)]="dateField"
    class="input-text middle-text-child w-100"
    autocomplete="off"
    [placeholder]="element.placeholder"
    [title]="element.placeholder"
    [minDate]="minDate"
    [maxDate]="maxDate"
    #fromdp="bsDatepicker"
    bsDatepicker
    (bsValueChange)="onDateValueChange($event)"
    readonly
    value="{{ dateField | date : 'yyyy-MM-dd' }}"
    [bsConfig]="{
      isAnimated: true,
      containerClass: 'theme-orange',
      dateInputFormat: 'YYYY-MM-DD',
      showWeekNumbers: false
    }"
  />
  <span class="clear-icon" *ngIf="dateField">
    <i (click)="onResetDate()">&times;</i>
  </span>
</div>

<!-- To Date -->
<!-- <div class="date">
  <input
    id="toDate"
    name="toDate"
    [formControl]="control"
    type="text"
    [(bsValue)]="toDate"
    class="input-text middle-text-child w-200px"
    autocomplete="off"
    placeholder="To Date"
    [minDate]="toMinDate"
    [maxDate]="toMaxDate"
    #todp="bsDatepicker"
    bsDatepicker
    readonly
    value="{{ toDate | date : 'yyyy-MM-dd' }}"
    [bsConfig]="{
      isAnimated: true,
      containerClass: 'theme-orange',
      dateInputFormat: 'YYYY-MM-DD',
      showWeekNumbers: false
    }"
  />

  <span class="clear-icon" *ngIf="toDate">
    <i (click)="onResetToDate()">&times;</i>
  </span>
</div> -->
