<div class="form-control-with-close-icon">
  <select
    class="form-control input-select form-select w-100"
    [formControl]="control"
    [title]="element.placeholder"
    [ariaPlaceholder]="element.placeholder"
    [ngClass]="
      element.itemIndex === 0 ? 'first-text-child' : 'middle-text-child'
    "
  >
    <option [ngValue]="null" disabled selected>
      {{ element.placeholder }}
    </option>
    <option *ngFor="let item of dataSource" [value]="item.code">
      {{ item.name }}
    </option>
  </select>

  <span class="clear-icon" style="right: 1px" *ngIf="control?.value">
    <i (click)="onResetData()">&times;</i>
  </span>
</div>

<div class="invalid" *ngIf="control?.hasError('pattern')">
  Please enter only numbers.
</div>
