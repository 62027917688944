import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, mergeMap } from 'rxjs';
import { SolrSearchService } from 'src/app/services/solarSearch.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { Element } from 'src/app/utils/constants';

@Component({
  selector: 'app-auto-complete-text',
  templateUrl: './auto-complete-text.component.html',
  styleUrls: ['./auto-complete-text.component.scss'],
  host: {
    class: 'col-sm-12 col-md z-index-9 mb-1 mb-md-0',
  },
})
export class AutoCompleteTextComponent implements OnInit {
  @Input() element: Element;
  @Input() control: FormControl;

  dataSource: Observable<any>;

  constructor(
    private solrSearchService: SolrSearchService,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit() {
    this.autoCompleteSuggestion();
  }

  autoCompleteSuggestion() {
    this.dataSource = new Observable((observer: any) => {
      if (this.control.value.length >= 3) {
        observer.next(this.control.value);
      }
    }).pipe(
      mergeMap(() => {
        return this.solrSearchService.getSuggestionsByCore(
          this.element.api,
          this.control.value,
          this.element.apiParameter
        );
      })
    );
  }

  onTitleSelectionTypeahead(event) {
    if (event.item !== '') {
      const str = String(event.item).replace(/<\/b>|<b>/gi, '');
      this.control.setValue(str);
    }
  }

  onResetData() {
    this.control.reset();
  }
}
