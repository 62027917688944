<div *ngIf="responseData !== undefined && responseData.length !== 0">
  <div class="row p0 align-items-center d-print-none">
    <div class="col-12 col-sm-12 col-md-6 mb-4 mb-md-0 sort-item">
      <span
        *ngIf="sortOptions && sortOptions.length > 0"
        class="fs12-mob sort-date"
      >
        <u><strong>Sort By:</strong></u
        >&nbsp;

        <div
          *ngFor="let option of sortOptions; let i = index"
          class="sort-option"
        >
          <a
            (click)="onSortBy(option)"
            class="sort-link"
            [ngClass]="{ selected: option.isActive }"
          >
            {{ option.displayName }}
            <i
              *ngIf="option.isActive"
              [ngClass]="getSortIcon(option)"
              class="sort-icon fs-6"
            ></i>
            <span class="text-dark" *ngIf="i < sortOptions.length - 1">
              |
            </span>
          </a>
        </div>
      </span>
    </div>
    <div class="col-md-3 col-sm-6 col-6">
      <span
        >{{ start }} - {{ end }} of {{ pagination?.totalRecords }}
        {{ type }}</span
      >
    </div>
    <div class="col-md-3 col-sm-6 col-6">
      <div style="float: right" class="fs12-mob">
        <label class="label-normal">Page Size:&nbsp;</label>
        <select
          name="pageSize"
          class="custom-select fs12-mob"
          style="width: auto"
          [(ngModel)]="pageSize"
          (change)="onPageSelected($event, true)"
        >
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="20">20</option>
        </select>
      </div>
    </div>
  </div>

  <ul>
    <li class="card-view" *ngFor="let job of responseData; let i = index">
      <div class="inherit">
        <ng-container
          *ngTemplateOutlet="
            templateFromParent;
            context: { $implicit: job, index: i }
          "
        ></ng-container>
      </div>
    </li>
  </ul>
  <div class="row g-0 d-print-none">
    <div class="col-4 col-md-2">
      <button
        (click)="onShowPreviousPage()"
        class="btn btn-primary w-100"
        [disabled]="currentPageCount === 0"
      >
        Previous
      </button>
    </div>
    <div class="col-4 col-md-8"></div>
    <div class="col-4 col-md-2 text-end">
      <button
        (click)="onShowNextPage()"
        class="btn btn-primary w-100"
        [disabled]="totalViewedRecords >= pagination?.totalRecords"
      >
        Next
      </button>
    </div>
  </div>
</div>
