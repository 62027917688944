import { Injectable } from '@angular/core';
import { Subject, Observable, of, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  private spinnerSubject = new BehaviorSubject<boolean>(false);
  public spinnerState = this.spinnerSubject.asObservable();

  constructor() {
    // setTimeout(() => {
    //   this.spinnerState = this.spinnerSubject.asObservable();
    //   this.spinnerSubject.next(true);
    // });
  }

  start() {
    this.spinnerSubject.next(true);
  }

  stop() {
    this.spinnerSubject.next(false);
  }
}
