<div class="container">
    <div class="row mt-5">
        <div class="col-md-7 col-xs-12">
            <div class="map-contact1 p-5">
                <div class="separator-header font-bold fs16-lg">
                    <h5 class="mb-0 font-bold fs16-lg">Contact Us</h5>
                </div>
                <div class="row">
                    <div class="col-xs-12">
                        <form [formGroup]="contactusControls" #contactusForm="ngForm"
                            (ngSubmit)="onSubmit(contactusForm)">
                            <div class="form-group row required">
                                <div class="col-6">
                                    <label for="inputName" class="mb-2 labelText">First Name <span
                                            class="require">*</span></label>
                                    <input type="text" id="inputName" class="form-control inputHeight" formControlName="firstName"
                                        placeholder="Enter Your First Name" [maxLength]="100">
                                        <span *ngIf="contactusControls.get('firstName').touched && contactusControls.get('firstName').errors?.['required']"
                                        class="text-danger p-1">First Name is required.</span>
                                        <span *ngIf="contactusControls.get('firstName').touched && this.contactusControls.get('firstName')?.errors?.['pattern']"
                                        class="text-danger p-1">Incorrect Pattern</span>
                                </div>
                                <div class="col-6">
                                    <label for="inputName" class="mb-2 labelText">Last Name <span
                                            class="require">*</span></label>
                                    <input type="text" id="inputLastname" class="form-control inputHeight" formControlName="lastName"
                                        placeholder="Enter Your Last Name" [maxLength]="100">
                                    <span *ngIf="contactusControls.get('lastName').touched && contactusControls.get('lastName').errors?.['required']"
                                        class="text-danger p-1">Last Name is required.</span>
                                    <span *ngIf="contactusControls.get('lastName').touched && this.contactusControls.get('lastName')?.errors?.['pattern']"
                                        class="text-danger p-1">Incorrect Pattern</span>
                                </div>
                            </div>
                            <div class="form-group row required mb-4">
                                <div class="col-6">
                                    <label for="inputName" class="mb-2 labelText">Email Address <span
                                            class="require">*</span></label>
                                    <input type="email" id="inputEmail" class="form-control inputHeight" formControlName="email"
                                        placeholder="Enter Your Email Address" [maxLength]="100">
                                    <span *ngIf="contactusControls.get('email').touched && contactusControls.get('email').errors?.['required']"
                                        class="text-danger p-1">Email is required.</span>
                                    <span *ngIf="contactusControls.get('email').touched && this.contactusControls.get('email')?.errors?.['pattern']"
                                        class="text-danger p-1">Incorrect Pattern</span>
                                </div>
                                <div class="col-6">
                                    <label for="inputName" class="mb-2 labelText">Phone Number <span
                                            class="require">*</span></label>
                                    <div class="input-group">
                                        <span class="input-group-text" id="basic-addon1">{{defaultcountycode}}</span>
                                        <input phoneMask id="inputNumber" class="form-control inputHeight" formControlName="phone"
                                            placeholder="Enter Your Phone No.">
                                    </div>
                                    <span *ngIf="contactusControls.get('phone').touched && this.contactusControls.get('phone').errors?.['required']"
                                            class="text-danger p-1">Phone is required.
                                    </span>
                                    <span *ngIf="contactusControls.get('phone').touched && contactusControls.get('phone')?.hasError('minlength')"
                                        class="text-danger">Invalid Phone Number.
                                    </span>
                                </div>
                            </div>
                            <div class="form-group row required">
                                <div class="col-12">
                                    <label for="inputName" class="mb-2 labelText">Comments <span
                                            class="require">*</span></label>
                                    <textarea [maxLength]="500" formControlName="comments" class="form-control textAreaHeight"
                                        id="comment" placeholder="Enter Comments" name="txtcomment">
                                    </textarea>
                                    <span *ngIf="contactusControls.get('comments').touched && this.contactusControls.get('comments').errors?.['required']"
                                            class="text-danger p-1">Comments is required.</span>
                                </div>
                            </div>
                            <div class="form-group row required">
                                <div class="mt-3">
                                    <div class="g-recaptcha">
                                        <re-captcha name="captcha" data-size="normal" required
                                            siteKey="6Le6jJkUAAAAAEuAJQJytzKo7BhU2umPxPcfzduX"
                                            formControlName="recaptchaReactive">
                                        </re-captcha>
                                    </div>
                                    <div *ngIf="isCaptchaResolved()" class="text-danger p-1">
                                        Captcha must be solved
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-12">
                                    <button type="submit" class="btn btn-lg contactBtn">CONTACT</button>
                                    <span>{{message}}</span>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-5 col-xs-12">
            <div class="map-contact1 p-4">
                <div class="separator-header font-bold fs16-lg">
                    <h5 class="mb-0 font-bold fs16-lg">Office Address</h5>
                </div>
                <div class="row">
                    <div class="col-xs-12">
                        <div *ngFor="let address of addresses">
                            <h5 class="font-bold fs16-lg mb5 mt0">{{address.country}}</h5>
                            <span class="font-bold fs16-lg mb5 mt0">{{address.addressName}}</span><br>
                            <span>{{address.addressLn}}</span><br>
                            <span>{{address.city}}, {{address.state}}</span><br>
                            <span>{{address.country}} - {{address.postalCode}}</span><br>
                            <span>{{address.phoneNoLabel}}: {{address.phoneCountryCode}} {{address.phoneNo}}</span><br>
                            <span *ngIf="address.faxNo">{{address.faxNoLabel}}: {{address.faxCountryCode}}
                                {{address.faxNo}}</span><br>
                            <span class="mb20">
                                <strong>{{address.emailOneLabel}}: </strong>
                                <a href="mailto:{{address.emailOne}}"><u>{{address.emailOne}}</u></a>
                            </span><br>
                            <span class="mb20">
                                <strong>{{address.emailTwoLabel}}: </strong>
                                <a href="mailto:{{address.emailTwo}}"><u>{{address.emailTwo}}</u></a>
                            </span><br>
                            <span class="mb20">
                                <strong>{{address.emailThreeLabel}}: </strong>
                                <a href="mailto:{{address.emailThree}}"><u>{{address.emailThree}}</u></a>
                            </span><br><br>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>