import { FormGroup, FormControl, Validators } from '@angular/forms';

export const Constants = {
  COUNTRY: 'country',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  USER_ROLE: 'userRole',
  ID: 'id',
  USER_DATA: 'userData',
  FETCH_RESOLVER_DATA: 'fetchResolverData',
  PAGE_RELOAD: 'pageReload',
  COMPANY_ID: 'companyId',
};

export const APP_CONFIG = {
  AUTH_TOKEN: 'token',
  AUTH_USER: 'user',
  TOKEN_HEADER: 'Authorization',
  REFRESH_TOKEN: 'refreshToken',
};

export const APP_ENTITY = {
  COMPANY: 'COMPANY',
  PROFILE: 'PROFILE',
  MERCHANT: 'MERCHANT',
  OFFER: 'OFFER',
  JOB: 'JOB',
  SCHOOL: 'SCHOOL',
};

export const ROLES = {
  ADMIN: 'ADMIN',
  JOB_SEEKER: 'JOB_SEEKER',
  RECRUITER: 'RECRUITER',
};

export const PROFILE_TYPES = {
  PRIVATE: 'PRIVATE',
  PUBLIC: 'PUBLIC',
  FREELANCER: 'FREELANCER',
};

export class RegexPattern {
  public static Email = '[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}';
  public static Password =
    '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$';
  public static jobUrlRegex = '^(?=.*[a-zA-Z0-9])[A-Za-z0-9_-]+$';
  public static fax = '^[0-9]{10}$';
  public static postalcode = '^[0-9]{5}(?:-[0-9]{4})?$';
  public static decimal = '^[0-9]{0,8}(\\.[0-9]{1,2})?$';
  public static number = '^[1-9]+[0-9]*$';
}

export const REGISTER_TABS = {
  JOBSEEKER: 'JOBSEEKER',
  RECRUITER: 'RECRUITER',
};

export const REGISTER_SECTIONS = {
  ACCOUNT: 'ACCOUNT',
  PROFILE: 'PROFILE',
  CONTACT_DROPDOWN: 'CONTACT_DROPDOWN',
  CONTACT_POSTALCODE: 'CONTACT_POSTALCODE',
  GENERAL: 'GENERAL',
};

export interface SharedType {
  code: string;
  name: string;
  scope?: string;
  order?: number;
}

export const COUNTRIES = {
  US: 'USA',
  IN: 'India',
  AU: 'Australia',
  GB: 'United Kingdom',
  CA: 'Canada',
};

export const CURRENCIES = {
  US: '$',
  IN: '₹',
  AU: 'A$',
  GB: '£',
  CA: 'C$',
};

export function cleanObjects(obj): any {
  for (const propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === ''
    ) {
      delete obj[propName];
    }
  }
  return obj;
}

export function validateAllFormFields(formGroup: FormGroup) {
  //{1}
  Object.keys(formGroup.controls).forEach((field) => {
    //{2}
    const control = formGroup.get(field); //{3}
    if (control instanceof FormControl) {
      //{4}
      control.markAsTouched({ onlySelf: true });
    } else if (control instanceof FormGroup) {
      //{5}
      this.validateAllFormFields(control); //{6}
    }
  });
}

export interface SearchElement {
  elements: Element[];
  buttonLabel: string;
  searchApi: string;
  sortColumn: Array<string>;
  sortDirection: string;
  dateSearchDTOField: string;
}

export interface Element {
  itemIndex?: number;
  name: string;
  type: ElementType;
  placeholder: string;
  api: string;
  apiParameter: string;
  onlyNumber: boolean;
}

export const ElementName = {
  Id: 'id',
  Title: 'title',
  Zip: 'zip',
  FromDate: 'fromDate',
  ToDate: 'toDate',
  DropDown: 'dropdown',
  Email: 'email',
};

export enum ElementType {
  FreeText,
  AutoCompleteText,
  DateSelection,
  DropdownSelection,
}

export interface Company {
  id: number;
  name: string;
  description: string;
  logo: string;
  faxNo: string;
  industry: string;
  industryTypeCode: string;
  industryTypeValue: string;
  foundingYear: string;
  phoneCode: string;
  phoneNo: string;
  phoneExt: string;
  addressLn1: string;
  addressLn2: string;
  city: string;
  companyCode: string;
  companyPhotoUrl: string;
  state: string;
  stateCode: string;
  country: string;
  countryCode: string;
  postalCode: string;
  noOfEmployees: string;
  revenue: number;
  websiteUrl: string;
  canModify: true;
  spam: true;
  profileId: string;
  isPreferredEmployer: boolean;
  isVisaSponsor: boolean;
  avgRating: number;
  reviews: [];
  myReviewId: number;
}
export interface ImmigrationType {
  code: string;
  name: string;
  isChecked: boolean;
}

export interface contactUs {
  txtcomments: string;
  txtemail: string;
  txtfirstName: string;
  txtphone: string;
  txtlastName: string;
  txtcountycode?: string;
}

export enum JobStatusCodes {
  ACCEPTING = 'ACCEPTING',
  NOT_ACCEPTING = 'NOT_ACCEPTING',
  ON_HOLD = 'ON_HOLD',
}

export class Messages {
  public static MyHotJobSubheader: string =
    'This is your personal Job Hot List page, You may edit or delete the records on this page at any time. <br>Every Hot Job record has a life of 30 days from the created date after which it will be deleted automatically.';
  public static HotJobSubheader: string =
    'This is a Master list of all the hot jobs in our job portal at this time, you may search or contact people using the information on this page.';
  public static NoHotJobsFoundInDB: string =
    "No Records Found. Get started by adding your Hot Jobs by clicking <a href='hot-list/add-job'>Create Hot Job</a>";
  public static MyHotProfileSubheader: string =
    'This is your personal Profile Hot List page, You may edit or delete the records on this page at any time. <br>Every Hot Profile record has a life of 30 days from the created date after which it will be deleted automatically.';
  public static HotProfileSubheader: string =
    'This is a Master list of all the hot profiles in our job portal at this time, you may search or contact people using the information on this page.';
  public static NoHotProfilesFoundInDB: string =
    "No Records Found. Get started by adding your Hot Profiles by clicking <a href='hot-list/add-profile'>Create Hot Profile</a>";
  public static NoHotJobsFoundOnSearch: string = 'No Records Found.';
  public static educationMsg: string =
    'Please enter education to complete your profile';
  public static skillMsg: string =
    'Please enter skill to complete your profile';
  public static experienceMsg: string =
    'Please enter experience to complete your profile';
  public static ProfilePicMsg: string =
    'Please upload profile picture to complete your profile';
  public static descriptionMsg: string =
    'Please enter description to complete your profile';
}

export enum RoutePath {
  VIEW_MY_JOB = 'view-my-job',
  VIEW_JOB = 'view-job',
  VIEW_MY_PROFILE = 'view-my-profile',
  VIEW_PROFILE = 'view-profile',
}

export class RoleRoutes {
  public static adminRoutes = ['search-job', 'update-job'];
  public static recruiterRoutes = [
    'profile-search',
    'applications',
    'jobs-posted',
    'post-jobs',
    'clone-job',
  ];
  public static jobSeekerRoutes = ['job-search', 'applied-jobs'];
}
