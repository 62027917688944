import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { SolrSearchService } from 'src/app/services/solarSearch.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { Element, SearchElement, ElementType } from 'src/app/utils/constants';
import { AutoCompleteTextComponent } from '../auto-complete-text/auto-complete-text.component';
import { DateSelectionComponent } from '../date-selection/date-selection.component';
import { DropdownSelectionComponent } from '../dropdown-selection/dropdown-selection.component';
import { FreeTextComponent } from '../free-text/free-text.component';
import { DBSearch, DateSearch } from 'src/app/models/solrSearch';

@Component({
  selector: 'app-search-db',
  templateUrl: './search-db.component.html',
  styleUrls: ['./search-db.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchDbComponent implements OnInit, AfterViewInit {
  @Input() searchElement: SearchElement;
  searchFormGroup: FormGroup;

  @ViewChild('dynamicComponent', { read: ViewContainerRef, static: false })
  dynamicComponent: ViewContainerRef;

  elements: Element[];
  buttonLabel: string;
  searchApi: string;
  colCss: string;

  dateSearchDTO: DateSearch = new DateSearch();
  @Input() isBannerApplied: boolean;
  @Input() dbSearch: DBSearch;
  @Input() id: number;
  @Output() searchResultEvent = new EventEmitter<any>();
  @Output() clearResultEvent = new EventEmitter<any>();

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private spinnerService: SpinnerService,
    private toastrService: ToastrService,
    private solrSearchService: SolrSearchService
  ) {}

  ngOnInit() {
    this.elements = this.searchElement.elements;
    this.buttonLabel = this.searchElement.buttonLabel;
    this.searchApi = this.searchElement.searchApi;

    // Create a dynamic form control for each element
    const group = {};
    this.elements.forEach((element) => {
      group[element.name] = new FormControl(
        null,
        element.onlyNumber ? Validators.pattern(/^[0-9]*$/) : null
      );
    });

    this.searchFormGroup = new FormGroup(group);
    if(this.id){
      this.searchFormGroup.setValue({
        "intParam" : this.id
      });
      this.generateSearchData(this.searchFormGroup.value);
    }
    this.colCss = this.getCalcColCss();
  }

  ngAfterViewInit() {
    this.elements.forEach((element: Element, index: number) => {
      element.itemIndex = index;
      let componentFactory = this.getComponentType(element);

      // Create the component and add it to the dynamicComponent view container
      const componentRef =
        this.dynamicComponent.createComponent(componentFactory);
      componentRef.setInput(
        'control',
        this.searchFormGroup.get(element.name.toString())
      );
      componentRef.setInput('element', element);

      componentRef.changeDetectorRef.detectChanges();
    });
  }

  getComponentType(element: Element) {
    let componentFactory;
    switch (element.type) {
      case ElementType.FreeText:
        componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            FreeTextComponent
          );
        break;
      case ElementType.AutoCompleteText:
        componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            AutoCompleteTextComponent
          );
        break;
      case ElementType.DropdownSelection:
        componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            DropdownSelectionComponent
          );
        break;
      case ElementType.DateSelection:
        componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            DateSelectionComponent
          );
        break;
      default:
        componentFactory = null;
        throw new Error(`Invalid element type: ${element.type}`);
    }

    return componentFactory;
  }

  onSearchClick() {
    if (!this.isValidForm) {
      this.toastrService.warning('Please enter search criteria');
      return;
    }

    this.generateSearchData(this.searchFormGroup.value);
  }

  // Function to generate the search data based on dynamic form group
  generateSearchData(formData: any) {
    if (this.dbSearch.params) {
      for (let prop in this.dbSearch.params) {
        this.dbSearch[prop] = this.dbSearch.params[prop];
      }
      this.dbSearch.params = undefined;
    }

    for (let prop in formData) {
      this.dbSearch[prop] = formData[prop];
    }

    // check if all properties are empty
    if (this.checkProperties(formData) || this.checkProperties(this.dbSearch)) {
      this.dbSearch = (({ pagination, params }) => ({ pagination, params }))(
        this.dbSearch
      );
    }
    this.dbSearch.pagination.startIndex = 1;
    this.searchResults();
  }

  searchResults() {
    this.spinnerService.start();
    this.solrSearchService
      .getResults(this.searchElement.searchApi, this.dbSearch)
      .subscribe((response) => {
        window.scroll(0, 0);
        localStorage.setItem(
          'storePagingParams',
          JSON.stringify(this.dbSearch)
        );
        this.spinnerService.stop();
        this.searchResultEvent.emit({
          responseData: response.data,
          dbSearch: this.dbSearch,
        });
      }, err => {
        this.spinnerService.stop();
      });
  }

  onClearResult() {
    this.searchFormGroup.reset();
    this.clearResultEvent.emit();
  }

  get isValidForm(): boolean {
    return Object.values(this.searchFormGroup.controls).some(
      (control) => control.value
    );
  }

  getCalcColCss(): string {
    let cssClass = '';
    switch (this.elements.length) {
      case 1:
        cssClass = 'col-12 col-md-4 offset-md-3';
        break;
      case 2:
        cssClass = 'col-12 col-md-6 offset-md-2';
        break;
      case 3:
        cssClass = 'col-12 col-md-8 offset-md-1';
        break;
      case 4:
        cssClass = 'col-12 col-md-8 offset-md-1';
        break;
      case 5:
        cssClass = 'col-10';
        break;
    }
    return cssClass;
  }

  getBannerBackground() {
    return this.isBannerApplied ? 'bg-img' : 'no-bg';
  }

  checkProperties(obj) {
    for (let key in obj) {
      if (key == 'pagination' || key == 'params') continue;
      if (obj[key] !== null && obj[key] != '') return false;
    }
    return true;
  }
}
