export class Utils {
  public static sentenceCase(str: string): string {
    if (typeof str !== 'string') {
      return '';
    }
    return str.replace(
      /\w\S*/g,
      (txt) => `${txt.charAt(0).toUpperCase()}${txt.substr(1).toLowerCase()}`
    );
  }

  public static capitalFirstLetter(key: string): string {
    const words = key.split(/[_ ]+/);
    return words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  public static isNumeric(value) {
    return /^\d+$/.test(value);
  }
}
