import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment.development';
import { Result } from '../models/result';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  constructor(public httpClient: HttpClient) { }

  httpPost(url: string, param?: any): Observable<Result> {
    return this.httpClient.post(environment.apiUrlIp + url, param)
      .pipe(map(res => res as Result));
  }

  httpGet(url: string): Observable<Result> {
    return this.httpClient.get(environment.apiUrlIp + url)
      .pipe(map(res => res as Result));
  }

  httpPostFileDownload(url, body?) {
    return this.httpClient.post<any>(environment.apiUrlIp + url, body, { responseType: 'blob' as 'json' })
    .pipe(
      switchMap((blob) => {
        return new Observable((observer: Observer<any>) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob); // convert blob to base64
          reader.onloadend = function() {
            const result = reader.result as string;
            observer.next(result.split(',')[1]); // emit the base64 string result
          };
        });
      })
    );
  }
}
