<footer>
  <div class="container-fluid no-padding whiteSpace bg-orange mt-4">
    <div class="col-sm-12 col-xs-12 plr0">
      <div class="container">
        <div class="col-md-5 col-sm-6 col-xs-12"></div>
        <div class="col-md-7 col-sm-6 col-xs-12"></div>
      </div>
    </div>
  </div>

  <div class="container-fluid bg-footer pt-5">
    <div class="container">
      <div class="row">
        <ng-container *ngIf="!isLoggedIn">
          <div class="col-md-3 col-sm-6">
            <ul class="directories footer">
              <span>For Job Seekers</span>
              <li>
                <a [routerLink]="['/home']">Find Jobs</a>
              </li>
              <li>
                <a (click)="signIn(REGISTER_TABS.JOBSEEKER)">Sign Up</a>
              </li>
              
              <li>
                <a routerLink="/delete-profile/jobseeker"
                  >Delete Profile</a
                >
              </li>
            </ul>
          </div>
          <div class="col-md-3 col-sm-6">
            <ul class="nnouncements footer">
              <span>For Employers</span>
              <li>
                <a [routerLink]="['/login']">Sign In</a>
              </li>
              <li>
                <a (click)="signIn(REGISTER_TABS.RECRUITER)">Sign Up</a>
              </li>
              
              <li>
                <a routerLink="/delete-profile/recruiter"
                  >Delete Profile</a
                >
              </li>
            </ul>
          </div>
          <div class="col-md-3 col-sm-6">
            <ul class="magazines footer">
              <span>About Us</span>
              <li>
                <a [routerLink]="['/about-us']">About Hyring Spree</a>
              </li>
              <li>
                <a [routerLink]="['/contact-us']">Contact Us</a>
              </li>
            </ul>
          </div>
          <div class="col-md-3 col-sm-6">
            <ul class="others footer">
              <span>Resources</span>
              <li>
                <a (click)="onClickPolicyTerms('PRIVACY_POLICY-')"
                  >Privacy Policy</a
                >
              </li>
              <li>
                <a (click)="onClickPolicyTerms('TERMS_CONDITION-')"
                  >Terms of Use</a
                >
              </li>
              <!-- <li>
                          <a href="#">Help</a>
                      </li> -->
            </ul>
          </div>
          <div class="footer-divider col-md-12 col-sm-12 col-xs-12"></div>
        </ng-container>
        <ng-container *ngIf="isLoggedIn">
          <div class="col-lg-6">
            <ul class="magazines footer">
              <span>About Us</span>
              <li>
                <a [routerLink]="['/about-us']">About Hyring Spree</a>
              </li>
              <li>
                <a [routerLink]="['/contact-us']">Contact Us</a>
              </li>
              <!-- <li>
                          <a href="#">Customers</a>
                      </li> -->
            </ul>
          </div>
          <div class="col-lg-6">
            <ul class="others footer">
              <span>Resources</span>
              <li>
                <a (click)="onClickPolicyTerms('PRIVACY_POLICY-')"
                  >Privacy Policy</a
                >
              </li>
              <li>
                <a (click)="onClickPolicyTerms('TERMS_CONDITION-')"
                  >Terms of Use</a
                >
              </li>
              <!-- <li>
                          <a href="#">Help</a>
                      </li> -->
            </ul>
          </div>
        </ng-container>
        <div class="row d-print-none">
          <div class="col-md-6 col-sm-6 col-xs-12">
            <ul class="social-footer d-flex">
              <li>
                <a href=" https://www.facebook.com/hyringspree" target="_blank">
                  <i class="fa-brands fa-facebook fa-2x"></i>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/HyringSpree" target="_blank">
                  <i class="fa-brands fa-twitter fa-2x"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/hyringspree"
                  target="_blank"
                >
                  <i class="fa-brands fa-linkedin fa-2x"></i>
                </a>
              </li>
            </ul>
          </div>
          <div
            class="col-md-6 col-sm-6 col-xs-12 d-flex copy-content justify-content-end"
          >
            <p class="copy-right">
              Copyright © {{ currentyear }} - Hyring Spree
            </p>
            <div class="siteseal" class="pl-2">
              <span id="siteseal"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
