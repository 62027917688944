export const URLS = {
  login: '/login',
  refreshToken: '/auth/refreshToken',
  getAuthProfile: '/auth/getUser',
  getFreelanceProfile: '/freelance/jobSeeker/getProfile',
  getFreelanceProfileByCode: '/auth/getFreelanceProfile',
  getPublicProfile: '/publicProfile/jobSeeker/getPublicProfile',
  getPublicProfileByCode: '/auth/getPublicProfile',
  getRecruiterAuthProfile: '/auth/getRecruitersProfileWithVisits',
  register: '/public/auth/registerUser',
  forgotPassword: '/public/auth/forgotPassword',
  verifyEmailCode: '/public/auth/verifyEmailCode',
  activateUser: '/public/auth/activateUser',
  validateEmail: '/public/validateEmail',
  updatePassword: '/public/auth/updatePassword',
  addHMail: '/auth/addHMail',
  authUser: '/auth/getUser',
  openManual: '/public/master/manual/get',
  downloadSample: '/master/manual/get',
  getMenu: '/master/getMenu',
  downloadFile: '/master/downloadBulkUploadTemplateUrl',
  verifyHmailId: '/public/auth/verifyHsId',
  jobSeekerSearchJobs: '/public/solr/job/search',
  recruiterSearchProfiles: '/solr/profile/search',
  suggest: '/public/solr/suggest',
  profileSuggest: '/public/solr/jobTitle/suggest',
  jobseekerNameSuggest: '/public/solr/jobSeekerName/suggest',
  institutionSuggest: '/public/master/school/searchAllForDropDown',
  companySearch: '/public/solr/company/search',
  companySuggest: '/public/master/company/searchAllForDropDown',
  profileSearch: '/solr/profile/search',
  schoolSearch: '/public/solr/school/search',
  recruiterSearch: '/public/solr/recruiters/search',
  recruiterSuggest: '/public/solr/recruiters/suggest',
  schoolSuggest: '/public/solr/school/suggest',
  offerSearch: '/public/solr/offers/search',
  offerSuggest: '/public/solr/offers/suggest',
  recruiterFreelancerSearch: '/solr/freelancerProfile/search',
  publicProfileSearch: '/solr/publicProfile/search',
  recruiterProfileSearch: '/public/solr/jobTitle/suggest',
  saveFutureJob: '/futureJob/postJob',
  getFutureJob: '/futureJob/getJob',
  searchFutureJob: '/futureJob/searchJob',
  deleteFutureJob: '/futureJob/deleteJob',
  getIndustry: '/public/master/industry/getAll',
  saveCompany: '/master/company/saveCompany',
  uploadCompanyPhoto: '/admin/uploadCompanyPhoto',
  deleteCompanyPhoto: '/admin/deleteCompanyPhoto',
  deleteCompany: '/admin/deleteCompany',
  getCompany: '/public/master/getCompany',
  reviewList: '/master/company/review/list',
  getRecruiterByCompany:'/auth/getRecruiterByCompany',
  getAllJobsByCompany: '/job/getAllJobsByCompany',
  myReviewId: '/master/company/review/myReviewId',
  changePassword: '/auth/changePassword',
  deleteAccount: '/auth/deleteAccount',
  deleteAdminAccount: '/admin/deleteUser',

  //Master List
  getExperienceLevelList: '/public/master/experienceLevel/jobSeeker/getAll',
  getExperienceLevelForJob: '/public/master/experienceLevel/job/getAll',
  getEducationLevels: '/public/master/educationLevel/getAll',
  getStateList: '/public/master/state/getAll',
  getCommutingType: '/public/master/commutingType/getAll',
  getImmigrationList: '/public/master/immigration/getAll?scope=REGISTER',
  getImmigrationListJobPost: '/public/master/immigration/getAll?scope=JOB_POST',
  getAllCities: '/public/master/city/getAll',
  lookingForJob: '/public/master/lookingForJob/getAll',
  countryData: '/public/master/countryPhoneCode/getAll',
  getAllCountries: '/public/master/country/getAll',
  getZipDetails: '/public/master/postalCode/get',
  getSkillType: '/public/master/skillType/getAll',
  officeAddress: '/public/master/getOfficeAddresses',
  getVisaStatus: '/public/master/visaType/getAll',
  // job-seeker
  appliedJobs: '/jobseeker/job/getAppliedJobs',
  upadateNotes: '/jobseeker/job/updateNotes',
  deleteJobApplication: '/jobseeker/job/withdrawApplyJob',
  getJob: '/job/getJob',
  viewAllJobs: '/public/job/get',
  anynomousUserGetCompany: '/public/master/company/get',
  anynomousUserGetProfile: '/public/auth/getProfile',
  addToFavoriteJob: '/jobseeker/job/addToFavoriteJobs',
  getFavoriteJobs: '/jobseeker/job/getFavoriteJobs',
  getFavoriteProfiles: '/recruiter/getFavoriteProfiles',
  deleteFavoriteJob: '/jobseeker/job/removeFromFavoriteJobs',
  mailResume: '/master/mailResume',
  applyJob: '/jobseeker/job/applyJob',
  shareJobWithFriend: '/job/shareJobWithFriend',
  updateSharableCode: '/recruiter/job/updateSharableCode',
  getAllCompanyListForDropDown: '/public/master/company/getAllForDropDown',
  reportSpam: '/master/reportSpam',
  saveExperiences: '/jobseeker/saveExperience',
  saveCertification: '/jobseeker/saveCertification',
  saveSkills: '/jobseeker/saveSkill',

  saveEducation: '/jobseeker/saveEducation',
  saveMembership: '/jobseeker/saveMembership',
  savePatent: '/jobseeker/savePatent',
  savePublication: '/jobseeker/savePublication',
  deleteExperience: '/jobseeker/deleteExperience',
  deleteCertificate: '/jobseeker/deleteCertification',
  deleteSkill: '/jobseeker/deleteSkill',
  deleteEducation: '/jobseeker/deleteEducation',
  deleteMembership: '/jobseeker/deleteMembership',
  deletePatent: '/jobseeker/deletePatent',
  deletePublication: '/jobseeker/deletePublication',

  //Recruiter
  getAllApplicationStatus: '/public/master/jobStatus/getAll',
  getJobApplicationStatus: '/public/master/jobApplicationStatus/getAll',
  getjobStatus: '/public/master/jobStatus/getActive',
  updateJobStatus: '/recruiter/job/updateJobStatus',
  getJobCategoryList: '/public/master/jobCategory/getAll',
  getEmploymentList: '/public/master/employmentType/getAll',
  resetSharableCode: '/recruiter/job/resetSharableCode',
  getHotJob: '/hotList/getHotJob',
  getHotProfile: '/hotList/getHotProfile',
  myJobHotList: '/hotList/searchMyHotJobs',
  jobHotList: '/hotList/searchAllHotJobs',
  myProfileHotList: '/hotList/searchMyHotProfiles',
  profileHotList: '/hotList/searchAllHotProfiles',
  saveHotJob: '/hotList/saveHotJob',
  deleteHotJob: '/hotList/deleteHotJob',
  saveHotProfile: '/hotList/saveHotProfile',
  deleteHotProfile: '/hotList/deleteHotProfile',
  getJobsByRecruiterEmail: '/job/getJobsByRecruiterEmail',
  deleteJob: '/recruiter/job/deleteJob',
  cloneJob: '/recruiter/job/cloneJob',
  postJob: '/recruiter/job/postJob',
  bulkPostJob: '/recruiter/job/bulkPostJobs',
  renewJob: '/recruiter/job/reNewJob',
  validateBulkUploadCode: '/recruiter/job/validateBulkUploadCode',
  compensationDurationList: '/public/master/salaryDuration/getAll',
  saveReview: '/master/company/review/save',
  getReview: '/master/company/review/get',
  getcompanyData: '/master/company/get',

  //Contact
  contactNow: '/public/master/contactUs',
  listApplicationsByRecruiter: '/recruiter/job/listApplicationsByRecruiter',
  updateJobApplication: '/recruiter/job/updateJobApplicationStatus',

  // Profile
  resetPrivateProfileCode: '/jobseeker/resetPrivateProfileCode',
  updatePrivateProfileCode: '/jobseeker/updatePrivateProfileCode',
  shareProfileWithFriend: '/auth/shareProfileWithFriend',
  addToFavoriteProfile: '/recruiter/addToFavoriteProfiles',
  removeFromFavoriteProfile: '/recruiter/removeFromFavoriteProfiles',

  resetFreelancerProfileCode: '/freelance/jobSeeker/resetFreelancerProfileCode',
  updateFreelancerProfileCode:
    '/freelance/jobSeeker/updateFreelancerProfileCode',

  resetPublicProfileCode: '/publicProfile/jobSeeker/resetPublicProfileCode',
  updatePublicProfileCode: '/publicProfile/jobSeeker/updatePublicProfileCode',

  getExperiences: '/jobseeker/getExperiences',
  getSkill: '/jobseeker/getSkill',
  getEducations: '/jobseeker/getEducations',
  getCertifications: '/jobseeker/getCertifications',
  getMemberships: '/jobseeker/getMemberships',
  getPatents: '/jobseeker/getPatents',
  getPublications: '/jobseeker/getPublications',
  saveUser: '/auth/saveUser',
  saveProfile: '/freelance/jobSeeker/saveProfile',
  savePublicProfile: '/publicProfile/jobSeeker/savePublicProfile',
  searchPublicProfile: '/publicProfile/jobSeeker/searchPublicProfile',
  downloadSharePhoto: '/auth/downloadPhotoByCode',
  deleteProfilePhoto: '/auth/deleteProfilePhoto',
  uploadPhoto: '/auth/uploadProfilePhoto',
  downloadPhoto: '/auth/downloadPhoto',
  updateUserDetails: '/auth/saveUser',
  getPrivateSharedProfile: '/auth/getPrivateProfile',

  getFreelancerSkill: '/freelance/jobSeeker/getSkills',
  getFreelancerProject: '/freelance/jobSeeker/getProjects',
  saveFreelancerProject: '/freelance/jobSeeker/saveProject',
  saveFreelancerSkill: '/freelance/jobSeeker/saveSkill',
  deleteFreelancerProject: '/freelance/jobSeeker/deleteProject',
  deleteFreelancerSkill: '/freelance/jobSeeker/deleteSkill',

  unlinkCompany: '/recruiter/unlinkCompany',
  linkWithCompany: '/recruiter/linkWithCompany',
  searchByNameAndPostalCode: '/public/master/company/searchByNameAndPostalCode',
  searchByNameAndCityAndState:
    '/public/master/company/searchByNameAndCityAndState',
  getJobsByRecruiterCode: '/job/getJobsByRecruiterCode',
  listProjectsByRecruiterEmail:
    '/freelance/recruiter/listProjectsByRecruiterEmail',
  getFreelanceRecruiterProfile: '/freelance/recruiter/getProfile',
  getFreelanceRecruiterProject: '/freelance/recruiter/getProject',
  deleteFreelanceRecruiterProject: '/freelance/recruiter/deleteProject',
  saveFreelanceProject: '/freelance/recruiter/saveProject',

  createContact: '/contact/createContactRequest',
  manageContacts: '/contact/listMyContacts',
  getContactDetails: '/contact/getContactDetails',
  deleteContact: '/contact/deleteContact',
  listMyFeedbacks: '/feedback/listMyFeedbacks',
  deleteFeedback: '/feedback/deleteFeedback',
  sendFeedbackRequest: '/feedback/sendFeedbackRequest',
  searchEmployerProjects: '/freelance/recruiter/searchEmployerProjects',
  feedback: '/public/feedback',

  //admin
  getPrivateProfileByEmail: '/admin/getPrivateProfileByEmail',
  getRecruiterByEmail: '/admin/getRecruiterProfileByEmail',
  getPublicProfileByEmail: '/admin/getPublicProfileByEmail',
  getFreelancerProfileByEmail: '/admin/getFreelancerProfileByEmail',
  uploadContactsRequest: '/contact/uploadContactsRequest',
};
