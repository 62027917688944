import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Directive({
  selector: '[phoneMask]',
})
export class PhoneMaskDirective {

  private _phoneControl: AbstractControl;
  private _preValue: string;
  
  constructor(private el: ElementRef) {}

  @Input()
  set phoneControl(control: AbstractControl) {
    if(control) {
      this._phoneControl = control;
    }
  }

  @Input()
  set preValue(value: string) {
    this._preValue = value;
  }

  @HostListener('input', ['$event'])
  onInputChange(event: any) {
    // Remove all non-numeric characters from input value
    let phoneNumber = event.target.value.replace(/\D/g, '');
    event.target.value = phoneNumber;

    // Check if the phoneNumber is not empty
    if (phoneNumber.length > 0) {
      // Add hyphens to the phone number
      phoneNumber = phoneNumber.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      event.target.value = !phoneNumber[2]
        ? phoneNumber[1]
        : phoneNumber[1] +
          '-' +
          phoneNumber[2] +
          (phoneNumber[3] ? '-' + phoneNumber[3] : '');
    }
  }
}
