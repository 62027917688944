import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core';

import { MultiSelectOptions } from 'src/app/models/solrSearch';

@Component({
  selector: 'app-multiselect-panel',
  templateUrl: './multiselect-panel.component.html',
  styleUrls: ['./multiselect-panel.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class MultiselectPanelComponent implements OnInit {
  @Input() params: MultiSelectOptions[] = [];
  @Input() key!: string;
  @Output() checkBoxClicked = new EventEmitter<MultiSelectOptions>();
  search: string = '';
  searchData: any = {};
  checkedData: MultiSelectOptions[] = [];

  ngOnInit() {
    const paramsString = localStorage.getItem('params');
    if (paramsString) {
      this.params = JSON.parse(paramsString);
    }

    const checkedValueString = localStorage.getItem('checkedValue');
    if (checkedValueString) {
      const checkedValue = JSON.parse(checkedValueString);
      for (const key of Object.keys(checkedValue)) {
        if (key === this.key) {
          for (const value of checkedValue[key]) {
            const option = this.params.find((o) => o.actualName === value);
            if (option) {
              option.isSelected = true;
            }
          }
        }
      }
    }
  }

  onSelect(isSelected: boolean) {
    for (const option of this.params) {
      option.isSelected = isSelected;
      this.checkBoxClicked.emit(option);
    }
  }

  optionClicked(option: MultiSelectOptions) {
    option.isSelected = !option.isSelected;
    this.checkBoxClicked.emit(option);
  }

  setInput(event: any) {
    this.search = (event.target as HTMLInputElement).value;
  }
  onResetData() {
    this.search = '';
  }
}
