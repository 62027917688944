import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  SolrSearch,
  SortDirection,
  SortOption,
} from 'src/app/models/solrSearch';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LocalStorageService } from 'src/app/services/localStorage.service';
import { SolrSearchService } from 'src/app/services/solarSearch.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { URLS } from 'src/app/utils/URLS';
import {
  Constants,
  ElementName,
  ElementType,
  ROLES,
  SearchElement,
} from 'src/app/utils/constants';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  showResults: boolean = true;
  searchElement: SearchElement;
  filters: any;
  responseData: any[];
  paginationData: any;
  solrSearch = new SolrSearch();
  pagination: { nextMark: string; totalRecords: number; pageCount: number };
  sortOptions: SortOption[];
  country: string = '';
  searchPlaceholder: string = '';

  constructor(
    private solrSearchService: SolrSearchService,
    private spinnerService: SpinnerService,
    private authService: AuthenticationService,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {
    this.country = localStorage.getItem('country');
    this.updatePlaceholder();
    this.createSearchFilter();
    if (this.authService.isLoggedIn) {
      this.navigateToModule();
    }

    this.sortOptions = [
      {
        sortKey: 'posted_date',
        displayName: 'Posted Date',
        sortDirection: SortDirection.None,
        isActive: false,
      },
      {
        sortKey: 'state_code,city_s',
        displayName: 'Location',
        sortDirection: SortDirection.None,
        isActive: false,
      },
      {
        sortKey: 'score',
        displayName: 'Relevance',
        sortDirection: SortDirection.None,
        isActive: false,
      },
    ];
  }

  navigateToModule() {
    const role = this.localStorageService.getData(Constants.USER_ROLE);
    if (role.toUpperCase() === ROLES.JOB_SEEKER) {
      this.router.navigate(['job-search']);
    } else if (role.toUpperCase() === ROLES.RECRUITER) {
      this.router.navigate(['profile-search']);
    } else if (role.toUpperCase() === ROLES.ADMIN) {
      this.router.navigate(['job-search']);
    }
  }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  updatePlaceholder() {
    switch (this.country) {
      case 'US':
        this.searchPlaceholder = 'Zip (or) City, State code (or) State';
        break;
      case 'IN':
        this.searchPlaceholder = 'City, State Code (or) State';
        break;
      case 'AU':
        this.searchPlaceholder = 'State/Territory (or) City';
        break;
      case 'GB':
        this.searchPlaceholder = 'Constituent Country (or) City';
        break;
      case 'CA':
        this.searchPlaceholder = 'Province / Territory (or) City';
        break;
    }
  }

  onSearchResultResponse(event: any) {
    const data = event.responseData;
    this.solrSearch = event.solrSearch;
    this.showResults = data !== undefined && data['data'].length > 0;

    if (this.showResults) {
      this.responseData = data['data'];
      this.filters = data['filters'];
      this.pagination = data['pagination'];
    }

    this.spinnerService.stop();
  }

  onClearResultResponse() {
    this.showResults = true;
    this.responseData = undefined;
    this.filters = undefined;
    this.pagination = undefined;

    this.solrSearch.cursorMark = '*';
    this.solrSearch.pageMarks = [this.solrSearch.cursorMark];
    this.solrSearch.pageSize = 20;
  }

  onSearchResults(solrSearch: SolrSearch) {
    this.spinnerService.start();
    this.solrSearchService
      .getResults(URLS.jobSeekerSearchJobs, solrSearch)
      .subscribe((response) => {
        this.onSearchResultResponse({
          responseData: response.data,
          solrSearch: this.solrSearch,
        });
      });
  }

  onRedirectToViewJob(jobCode) {
    window.open('job-details/' + jobCode + '#job', '_blank');
  }

  onClearFilter() {
    this.solrSearch.cursorMark = '*';
    this.solrSearch.pageMarks = [this.solrSearch.cursorMark];
    this.solrSearch.pageSize = 20;
    this.onSearchResults(this.solrSearch);
  }

  createSearchFilter() {
    this.searchElement = {
      elements: [
        {
          name: ElementName.Title,
          type: ElementType.AutoCompleteText,
          placeholder: 'Job Title, Skill, Keyword',
          api: URLS.suggest,
          apiParameter: 'JOB_TITLES',
          onlyNumber: false,
        },
        {
          name: ElementName.Zip,
          type: ElementType.AutoCompleteText,
          placeholder: this.searchPlaceholder,
          api: URLS.suggest,
          apiParameter: 'CITY_STATE',
          onlyNumber: false,
        },
      ],
      buttonLabel: 'FIND JOBS',
      searchApi: URLS.jobSeekerSearchJobs,
      sortColumn: ['score'],
      sortDirection: 'desc',
      dateSearchDTOField: 'posted_date',
    };
  }
}
