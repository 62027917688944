import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  data$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor() {}

  setData(data: any) {
    this.data$.next(data);
  }

  resetData() {
    this.data$.next(null);
  }
}
