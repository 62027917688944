<div class="form-control-with-close-icon">
  <input
    [typeahead]="dataSource"
    [typeaheadWaitMs]="1000"
    (typeaheadOnSelect)="onTitleSelectionTypeahead($event)"
    [placeholder]="element.placeholder"
    [title]="element.placeholder"
    class="form-control input-text w-100"
    [ngClass]="
      element.itemIndex === 0 ? 'first-text-child' : 'middle-text-child'
    "
    [formControl]="control"
    autocomplete="off"
    maxlength="100"
    typeahead-append-to-body="true"
  />
  <span class="clear-icon" *ngIf="control?.value">
    <i (click)="onResetData()">&times;</i>
  </span>
</div>
