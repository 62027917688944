import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopFilterComponent } from './top-filter/top-filter.component';
import { SideFilterComponent } from './side-filter/side-filter.component';
import { CardComponentComponent } from './card-component/card-component.component';
import { FilterPipe } from './pipes/filter.pipe';

@NgModule({
  declarations: [
    TopFilterComponent,
    SideFilterComponent,
    CardComponentComponent,
    FilterPipe,
  ],
  imports: [CommonModule],
  exports: [
    TopFilterComponent,
    SideFilterComponent,
    CardComponentComponent,
    FilterPipe,
  ],
})
export class SharedModule {}
