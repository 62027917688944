import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from './base-service';
import { Utils } from '../utils/utils';

@Injectable({
  providedIn: 'root',
})
export class SolrSearchService extends BaseService {
  private stateSubject = new BehaviorSubject<boolean>(null);
  public stateSubject$ = this.stateSubject.asObservable();
  private searchDataSubject = new BehaviorSubject<any>(null);
  public searchDataSubject$ = this.searchDataSubject.asObservable();
  institutionList = [];
  companyList = [];

  /**
   * Search solr results
   */
  getResults(url: string, data: any) {
    return this.httpPost(url, data);
  }

  getJobs(url: string) {
    return this.httpGet(url);
  }

  clearFilter() {
    this.stateSubject.next(true);
  }
  apply(data: any) {
    this.searchDataSubject.next(data);
  }

  getSuggestionsByCore(
    url: string,
    title: string,
    core: string
  ): Observable<any> {
    return this.httpPost(url, {
      core: Utils.isNumeric(title) ? 'ZIP' : core,
      searchString: title,
    }).pipe(
      map((res) => {
        res.data = res.data.map((el) => this.extractContent(el));
        return res.data;
      })
    );
  }

  extractContent(s) {
    var span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  }

  getInstitutionSuggestByCore(url: string, title: string): Observable<any> {
    return this.httpPost(url, {
      pageNumber: {
        pageSize: 20,
      },
      param: title,
    }).pipe(
      map((res) => {
        this.institutionList = res.data.data;
        var names = res.data.data.map(function (item) {
          return item['name'];
        });
        return names;
      })
    );
  }

  getCompanySuggestByCore(url: string, title: string): Observable<any> {
    return this.httpPost(url, {
      pageNumber: {
        pageSize: 20,
      },
      param: title,
    }).pipe(
      map((res) => {
        this.companyList = res.data.data;
        var names = res.data.data.map(function (item) {
          return item['name'];
        });
        return names;
      })
    );
  }

  getZipSuggest(url: string, zip: string): any {
    let obj: any;
    if (Utils.isNumeric(zip) === true) {
      obj = {
        core: 'ZIP',
        // 'dictionaries': [
        // 	'zipcodeSuggester'
        // ],
        searchString: zip,
      };
    } else {
      obj = {
        core: 'CITY_STATE',
        // 'dictionaries': [
        // 	'citySuggester'
        // ],
        searchString: zip,
      };
    }
    return this.httpPost(url, obj).pipe(
      map((res) => {
        return res.data;
      })
    );
  }
}
