import { Constants } from './../utils/constants';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, catchError, map, Observable, of } from 'rxjs';
import { AuthenticatedUserModel } from '../models/authenticated-user.model';
import { Result } from '../models/result';
import { ValidateEmail } from '../models/validateemail';
import { APP_CONFIG } from '../utils/constants';
import { BaseService } from './base-service';
import { LocalStorageService } from './localStorage.service';
import { URLS } from 'src/app/utils/URLS';

@Injectable()
export class AuthenticationService extends BaseService {
  authenticatedUser: AuthenticatedUserModel;
  authenticatedUser$: BehaviorSubject<AuthenticatedUserModel> =
    new BehaviorSubject<AuthenticatedUserModel>(null);
  userCountry: BehaviorSubject<string> = new BehaviorSubject<string>('');
  signInJobseeker: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  signInActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isViewMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    httpClient: HttpClient,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {
    super(httpClient);
  }

  get isLoggedIn(): boolean {
    return !!this.localStorageService.getData(APP_CONFIG.AUTH_TOKEN);
    //return !!localStorage.getItem(APP_CONFIG.AUTH_TOKEN);
  }

  get getAuthToken() {
    return this.authenticatedUser.token;
  }

  get isAuthenticatedUser(): boolean {
    return (
      this.authenticatedUser &&
      this.authenticatedUser.id != null &&
      this.authenticatedUser.id > 0
    );
  }

  public getAuthUser(): AuthenticatedUserModel {
    return this.authenticatedUser;
  }

  getRecruiterAuthProfile(recruiterId: number): Observable<Result> {
    return this.httpPost(URLS.getRecruiterAuthProfile, {
      intParam: recruiterId,
    });
  }

  setAuthenticatedUser(userModel: AuthenticatedUserModel) {
    this.localStorageService.saveData(
      Constants.USER_DATA,
      JSON.stringify(this.authenticatedUser)
    );
    this.authenticatedUser$.next(userModel);
  }

  public getloginUser(): Observable<Result> {
    return this.httpPost(URLS.authUser, {});
  }

  login(email: string, password: string): Observable<any> {
    return this.httpPost(URLS.login, {
      email: email,
      password: password,
    }).pipe(
      map((response) => {
        const res: Result = response as Result;
        if (res && res !== null && res.header.success === true) {
          this.authenticatedUser = res.data as AuthenticatedUserModel;

          // Store Local Storage Data
          this.localStorageService.clearData();

          //country
          this.localStorageService.saveData(
            Constants.COUNTRY,
            this.authenticatedUser.country
          );

          //token
          this.localStorageService.saveData(
            APP_CONFIG.AUTH_TOKEN,
            this.authenticatedUser.token
          );

          //refresh token
          this.localStorageService.saveData(
            APP_CONFIG.REFRESH_TOKEN,
            this.authenticatedUser.refreshToken
          );

          //user role
          this.localStorageService.saveData(
            Constants.USER_ROLE,
            this.authenticatedUser.roles[0]
          );

          this.setAuthenticatedUser(this.authenticatedUser);
        }
        return res.data;
      })
    );
  }

  logout() {
    this.localStorageService.clearData();
    this.authenticatedUser$.next(undefined);
    return true;
  }

  refreshToken() {
    localStorage.setItem(
      APP_CONFIG.AUTH_TOKEN,
      localStorage.getItem(APP_CONFIG.REFRESH_TOKEN)
    );
    return this.httpPost(URLS.refreshToken).pipe(
      map(
        (res) => {
          this.authenticatedUser = res.data as AuthenticatedUserModel;
          localStorage.setItem(
            APP_CONFIG.AUTH_TOKEN,
            this.authenticatedUser.token
          );
          localStorage.setItem(
            APP_CONFIG.REFRESH_TOKEN,
            this.authenticatedUser.refreshToken
          );

          if (res && res !== null) {
            this.authenticatedUser.token = res['token'];
          }
          return res;
        },
        (err) => {
          if (err && err.status === 401) this.logout();
          this.router.navigate(['/login']);
          return of('');
        }
      )
    );
  }

  reloadAPI(x, y) {
    return this.httpClient.post(x, y).pipe(
      map((resp) => {
        resp as Result;
      })
    );
  }

  validateEmail(validateEmail: ValidateEmail) {
    return this.httpPost(URLS.validateEmail, validateEmail).pipe(
      map((res) => {
        return res;
      })
    );
  }
  verifyEmailCode(token: string): Observable<Result> {
    return this.httpPost(URLS.verifyEmailCode, { strParam: token }).pipe(
      map((res) => {
        return res as Result;
      })
    );
  }

  saveHmail(hsId: string): Observable<Result> {
    return this.httpPost(URLS.addHMail, { strParam: hsId });
  }

  verifyHMailId(hMailId): Observable<any> {
    return this.httpPost(URLS.verifyHmailId, { strParam: hMailId });
  }
  
  activateUser(email: string, token: string): Observable<Result> {
    return this.httpPost(URLS.activateUser, { email, token }).pipe(
      map(res => {
        return res as Result;
      })
      );
    }
      
  updatePassword(validateEmail: ValidateEmail): Observable<Result> {
    return this.httpPost(URLS.updatePassword, validateEmail).pipe(
      map((res) => {
        return res as Result;
      })
    );
  }
  register(userData: any): Observable<any> {
    return this.httpPost(URLS.register, userData);
  }

  forgotPassword(emailValue: any) {
    return this.httpPost(URLS.forgotPassword, {
      strParam: emailValue,
    });
  }

  getMenu(role): Observable<Result> {
    return this.httpGet(`${URLS.getMenu}/${role}`);
  }

  getAddresses(): Observable<Result> {
    return this.httpGet(URLS.officeAddress);
  }
  
  getCountry() {
    return this.httpClient.get('https://ipapi.co/json');
  }

}
