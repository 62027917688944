import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { SpinnerService } from '../services/spinner.service';

@Component({
  selector: 'app-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.scss'],
})
export class BodyComponent implements OnInit {
  isLoggedIn: boolean = false;
  showHideSpinner: boolean = false;
  disableHeader: boolean = false;
  
  constructor(
    public authService: AuthenticationService,
    public spinnerService: SpinnerService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.authService.authenticatedUser$.subscribe((value) => {
      this.isLoggedIn = this.authService.isLoggedIn;
    });
    this.authService.isViewMode.subscribe(res => {
      if (res !== undefined) {
        this.disableHeader = res;
      }
    })
    this.spinnerService.spinnerState.subscribe((state) => {
      this.showHideSpinner = state;
      this.changeDetectorRef.detectChanges();
      //spinnerService?.spinnerState
    });
  }
}
