import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { URLS } from 'src/app/utils/URLS';
import { Result } from '../models/result';
import { BaseService } from './base-service';
import { HotJob } from '../models/hotjob-model';

@Injectable()
export class CommonService extends BaseService {

  public onImmgUpdateSubject = new Subject<any>();
  public onJobUpdateSubject = new Subject<any>();

  constructor(
    httpClient: HttpClient
  ) {
    super(httpClient);
  }

  getResults(url: string, data: any) {
    return this.httpPost(url, data);
  }

  openManual(type): Observable<Result> {
    return this.httpPost(URLS.openManual, { strParam: type });
  }

  downloadSample(type): Observable<Result> {
    return this.httpPost(URLS.downloadSample, { strParam: type });
  }

  getStates(): Observable<Result> {
    return this.httpPost(URLS.getStateList, {});
  }

  getCities(stateCode: string): Observable<Result> {
    return this.httpPost(URLS.getAllCities, { strParam: stateCode });
  }

  downloadFile(type): Observable<Result> {
    return this.httpPost(URLS.downloadFile, { strParam: type });
  }

  getZipDetails(zipCode: string): Observable<Result> {
    return this.httpPost(URLS.getZipDetails, { 'strParam': zipCode });
  }

  contactNow(contact: any): Observable<Result>{
    return this.httpPost(URLS.contactNow, contact);
  }

  getAddresses(): Observable<Result> {
    return this.httpGet(URLS.officeAddress);
  }

  getEmploymentList(): Observable<Result> {
    return this.httpPost(URLS.getEmploymentList, {});
  }

  getExperienceLevelList(): Observable<Result> {
    return this.httpPost(URLS.getExperienceLevelList, {});
  }

  getVisaStatusList(): Observable<Result> {
    return this.httpPost(URLS.getVisaStatus, {});
  }

  getCommutingType(): Observable<Result> {
    return this.httpPost(URLS.getCommutingType, {});
  }

  getCountryData(): Observable<Result> {
    return this.httpPost(URLS.countryData, {"boolParam": true});
  }

  getHotJob(hotJobId: number) {
    return this.httpPost(URLS.getHotJob, {intParam: hotJobId});
  }

  saveHotJob(hotJob: HotJob): Observable<Result> {
    return this.httpPost(URLS.saveHotJob, hotJob);
  }

  deleteHotJob(id) {
    return this.httpPost(URLS.deleteHotJob, { intParam: id });
  }

  getHotProfile(hotProfileId: number) {
    return this.httpPost(URLS.getHotProfile, {intParam: hotProfileId});
  }

  saveHotProfile(hotProfile: HotJob): Observable<Result> {
    return this.httpPost(URLS.saveHotProfile, hotProfile);
  }

  deleteHotProfile(id) {
    return this.httpPost(URLS.deleteHotProfile, { intParam: id });
  }
  
  getExperienceLevelForJob(): Observable<Result> {
    return this.httpPost(URLS.getExperienceLevelForJob);
  }

  public getImmigrationList_JOB_POST(): Observable<Result>{
    return this.httpPost(URLS.getImmigrationListJobPost, {});
  }
  
  public getAllCompanyListForDropDown (): Observable<Result> {
    return this.httpPost(URLS.getAllCompanyListForDropDown, {});
  } 

  public getJobCategoryList (): Observable<Result>{
    return this.httpPost(URLS.getJobCategoryList, {});
  } 

  public getCompensationDurationList (): Observable<Result> {
    return this.httpPost(URLS.compensationDurationList, {});
  } 

}
