import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationComponent } from './pagination/pagination.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FreeTextComponent } from './search/free-text/free-text.component';
import { AutoCompleteTextComponent } from './search/auto-complete-text/auto-complete-text.component';
import { DropdownSelectionComponent } from './search/dropdown-selection/dropdown-selection.component';
import { DateSelectionComponent } from './search/date-selection/date-selection.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { NoResultFoundComponent } from './no-result-found/no-result-found.component';
import { FilterComponent } from '../filter/filter.component';
import { MultiselectPanelComponent } from '../filter/multiselect-panel/multiselect-panel.component';
import { PaginationDbComponent } from './pagination-db/pagination-db.component';
import { SearchSolrComponent } from './search/search-solr/search-solr.component';
import { SearchDbComponent } from './search/search-db/search-db.component';
import { HomeSearchSolrComponent } from './search/home-search-solr/home-search-solr.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TypeaheadModule.forRoot(),
    BsDatepickerModule.forRoot(),
    SharedModule,
  ],
  declarations: [
    HomeSearchSolrComponent,
    SearchDbComponent,
    SearchSolrComponent,
    FreeTextComponent,
    AutoCompleteTextComponent,
    DropdownSelectionComponent,
    DateSelectionComponent,
    NoResultFoundComponent,
    FilterComponent,
    PaginationComponent,
    MultiselectPanelComponent,
    PaginationDbComponent,
  ],
  exports: [
    HomeSearchSolrComponent,
    SearchDbComponent,
    SearchSolrComponent,
    FilterComponent,
    PaginationComponent,
    PaginationDbComponent,
    NoResultFoundComponent,
  ],
})
export class GlobalSearchModule {}
