export class SolrSearch {
  searchString: string = null;
  locationString?: string = null;
  pageMarks?: string[];
  cursorMark: string = '';
  searchParams: any;
  sort: Sort[] = [];
  pageSize: number = 20;
  dateSearchDTO: DateSearch;
  hlField: string[];
  locationSearchDTO: LocationSearch;
}

export class DBSearch {
  constructor() {
    this.pagination = new dbPagination();
  }
  pagination: dbPagination
  params?: any;
}
export class dbPagination {
    sortDTO: Sort;
    startIndex: number = 1;
    pageSize: number = 20;
}

export class SolrFilters {
  title: string;
  key: string;
  list: MultiSelectOptions[];
}

export class Sort {
  sortColumn: string[] = ['id'];
  sortDirection: string = 'desc';
}

export class DateSearch {
  fieldName: string;
  from: string;
  to: string;
}

export class LocationSearch {
  distance: number;
  postal_code: string;
}

export class MultiSelectOptions {
  id: number = 0;
  name: string = '';
  actualName: string = '';
  isSelected: boolean = false;
  sortOrder: number = 0;
}

export class ListingObject {
  key: number;
  value: string;
  displayName: string;
}
export class KeyValuePair {
  constructor(k: number, v: string) {
    this.key = k;
    this.value = v;
  }
  key: number;
  value: string;
}

export interface DBPayload {
  pageSize: number;
  startIndex: number;
  sortDTO: Sort;
}

export class SortOption {
  sortKey: string;
  displayName: string;
  sortDirection: SortDirection;
  isActive: boolean;
}

export enum SortDirection {
  None = '',
  Ascending = 'asc',
  Descending = 'desc',
}
