<div class="d-print-none" [ngClass]="{ 'horizontal-tabmenu': isLoggedIn }">
  <div
    class="d-flex justify-content-center d-print-none"
    [ngClass]="{ 'd-none': !isLoggedIn }"
  >
    <div class="row m-0 p-0">
      <div class="col-sm-12 col-xs-12">
        <nav class="navbar navbar-expand p-0">
          <div class="collapse navbar-collapse">
            <ul class="navbar-nav sub-nav align-items-center">
              <li
                class="nav-item dropdown"
                *ngFor="let mn of menuList; let i = index"
              >
                <a
                  (click)="onLinkClick(mn.url, i)"
                  [ngClass]="{ 'active-navbar': selectedIndex == i }"
                  class="dropdown text-black"
                  data-toggle="dropdown-submenu"
                  aria-haspopup="true"
                  aria-expanded="false"
                  >{{ mn.title }}
                </a>
                <div
                  class="dropdown-menu"
                  *ngIf="mn.subMenus && mn.subMenus.length"
                >
                  <a class="dropdown-item" *ngFor="let subMenu of mn.subMenus">
                    <span (click)="onLinkClick(subMenu.url, i)"
                      >{{ subMenu.title }}
                    </span>
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </div>
</div>
<!-- Hamburger menu -->
<div class="vertical-tabmenu d-print-none">
  <nav class="navbar d-print-none">
    <div class="d-flex justify-content-between align-items-center w-100 ps-2">
      <div *ngIf="!isLoggedIn"></div>
      <!-- Navbar -->
      <div *ngIf="isLoggedIn">
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar"
          aria-controls="offcanvasNavbar"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="offcanvas offcanvas-start"
          tabindex="-1"
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
        >
          <div class="offcanvas-header">
            <!-- Header -->
            <h5 class="offcanvas-title" id="offcanvasNavbarLabel">
              Hyring Spree
            </h5>
            <!-- close button -->
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div class="offcanvas-body">
            <ul
              class="navbar-nav justify-content-end flex-grow-1 px-3 w-100 h-100"
            >
              <li class="nav-item" *ngFor="let mn of menuList; let i = index">
                <a
                  (click)="onLinkClick(mn.url, i)"
                  [ngClass]="{ 'active-navbar': selectedIndex == i }"
                  class="nav-link text-black"
                  data-bs-toggle="dropdown"
                  data-toggle="dropdown-submenu"
                  aria-haspopup="true"
                  aria-expanded="false"
                  data-bs-dismiss="offcanvas"
                  >{{ mn.title }}
                </a>
                <ul style="list-style: none">
                  <li
                    class="nav-item"
                    *ngIf="mn.subMenus && mn.subMenus.length"
                  >
                    <a
                      class="nav-link"
                      *ngFor="let subMenu of mn.subMenus"
                      data-bs-dismiss="offcanvas"
                    >
                      <span (click)="onLinkClick(subMenu.url, i)"
                        >{{ subMenu.title }}
                      </span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Country -->
      <div class="d-flex justify-content-end align-items-center pe-2">
        <!-- Country -->
        <div class="country pe-2">
          <span>Country</span>
        </div>

        <!-- Country Dropdown -->
        <div id="countryDropdown" class="dropdown pe-2">
          <div
            class="btn btn-default dropdown-toggle dropdown-wrap"
            [ngClass]="disableCountry ? '' : 'dropdown-wrap-disabled'"
            type="button"
            id="menu1"
            data-toggle="dropdown"
          >
            <span class="fi fis flag" [ngClass]="flagCountryCode"></span>
            <span class="country ml-2" *ngIf="country === 'IN'">India</span>
            <span class="country ml-2" *ngIf="country === 'US'">USA</span>
            <span class="country ml-2" *ngIf="country === 'CA'">Canada</span>
            <span class="country ml-2" *ngIf="country === 'GB'">UK</span>
            <span class="country ml-2" *ngIf="country === 'AU'">Australia</span>
          </div>
          <ul
            class="dropdown-menu custom-menu"
            [style.display]="disableCountry ? '' : 'none'"
            role="menu"
            aria-labelledby="menu1"
          >
            <li role="presentation" (click)="onCountryChange('au')">
              <span class="fi fi-au fis flag"></span
              ><span class="country ml-2">Australia</span>
            </li>
            <li role="presentation" (click)="onCountryChange('in')">
              <span class="fi fi-in fis flag"></span
              ><span class="country ml-2">India</span>
            </li>
            <li role="presentation" (click)="onCountryChange('gb')">
              <span class="fi fi-gb fis flag"></span
              ><span class="country ml-2">UK</span>
            </li>
            <li role="presentation" (click)="onCountryChange('us')">
              <span class="fi fi-us fis flag"></span
              ><span class="country ml-2">USA</span>
            </li>
            <li role="presentation" (click)="onCountryChange('ca')">
              <span class="fi fi-ca fis flag"></span
              ><span class="country ml-2">Canada</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</div>
