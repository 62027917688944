import {
  AbstractControl,
  FormGroup,
  ValidatorFn,
  ValidationErrors,
  Validators,
} from '@angular/forms';

export class CustomValidation {
  public static mobnumPattern = '^((\\+91-?)|0)?[0-9]{10}$';
  public static passwordPattern =
    '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$';
  public static txtNamePtn = Validators.pattern('[a-zA-Z][a-zA-Z ]+');
  public static emailPtn = Validators.pattern(
    '[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}'
  );
  public static phonePtn = Validators.pattern(
    /^(?:\s*)(\+1-?|)?(\d{3}-\d{3}-\d{4})(?:\s*)$/
  );
  public static bulkCodePtn = Validators.pattern('[A-Za-z0-9 ]+$');
}
export const MatchPassword: ValidatorFn = (
  control: FormGroup
): ValidationErrors | null => {
  const { value: password } = control.get('password');
  const { value: confirmPassword } = control.get('confirmPassword');
  if (password && confirmPassword) {
    if (password !== confirmPassword) {
      control.get('confirmPassword').setErrors({ MatchPassword: true });
    } else {
      return null;
    }
  }
  return null;
};

export const dateTimeLessThan: ValidatorFn = (
  control: FormGroup
): ValidationErrors | null => {
  const timeFrom = control.get('timeFrom');
  const timeTo = control.get('timeTo');

  if (timeFrom && timeTo) {
    if (timeFrom.value >= timeTo.value) {
      control.get('timeTo').setErrors({ InvalidTime: true });
    } else {
      return null;
    }
  }
  return null;
};

export function dateLessThan(from: string, to: string) {
  return (group: FormGroup): { [key: string]: any } => {
    let f = group.controls[from];
    let t = group.controls[to];
    if (f.disabled || t.disabled) {
      return {};
    }
    if (f.value > t.value) {
      return {
        dates: 'Date from should be less than Date to',
      };
    }
    return {};
  };
}

export function conditionalDateLessThan(
  from: string,
  to: string,
  hasCurrent: string
): ValidatorFn {
  return (formGroup: FormGroup): ValidationErrors | null => {
    const fromControl: AbstractControl | null = formGroup.get(from);
    const toControl: AbstractControl | null = formGroup.get(to);
    const currentCompanyControl: AbstractControl | null =
      formGroup.get(hasCurrent);

    if (
      !fromControl ||
      !toControl ||
      !currentCompanyControl ||
      fromControl.disabled ||
      toControl.disabled
    ) {
      return null;
    }

    const fromDate: Date = new Date(fromControl.value);
    const toDate: Date = new Date(toControl.value);

    if (currentCompanyControl.value === false && fromDate > toDate) {
      return {
        dateLessThan: true,
      };
    }

    return null;
  };
}

export const dateLessThanValidation: any = (
  dateField1: string,
  dateField2: string,
  validatorField: { [key: string]: boolean }
) => {
  return (c: AbstractControl): { [key: string]: boolean } | null => {
    const date1 = c.get(dateField1).value;
    const date2 = c.get(dateField2).value;
    if (date1 !== null && date2 !== null && date1 > date2) {
      return validatorField;
    }
    return null;
  };
};
