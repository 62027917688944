import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticatedUserModel } from 'src/app/models/authenticated-user.model';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LocalStorageService } from 'src/app/services/localStorage.service';
import { Constants, ROLES } from 'src/app/utils/constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  isMenuDisplayed: boolean = true;
  disableCountry: boolean = false;
  country: string | null = null;
  flagCountryCode: string | null = null;
  role: string;
  applyClass: boolean = false;
  disableHeader: boolean = false;
  loggedInUser: AuthenticatedUserModel = new AuthenticatedUserModel();

  constructor(
    private router: Router,
    public authenticationService: AuthenticationService,
    public localStorageService: LocalStorageService
  ) {
    this.authenticationService.signInActive.subscribe((res) => {
      if (res !== undefined) {
        this.applyClass = res;
      }
    });
    this.authenticationService.isViewMode.subscribe((res) => {
      if (res !== undefined) {
        this.disableHeader = res;
      }
    });
  }

  ngOnInit(): void {
    if (this.localStorageService.getData(Constants.COUNTRY) !== null) {
      this.country = this.localStorageService.getData(Constants.COUNTRY);
      this.flagCountryCode = `fi-${this.country.toLowerCase()}`;
    } else if (!this.authenticationService.isAuthenticatedUser) {
      this.getCountry();
    }

    this.authenticationService.authenticatedUser$.subscribe((value) => {
      this.disableCountry = value === null;
      // load country after login
      var country = this.localStorageService.getData(Constants.COUNTRY);
      if (country) {
        this.onCountryChange(country);
      }

      if (this.localStorageService.hasData(Constants.USER_DATA)) {
        this.loggedInUser = JSON.parse(
          this.localStorageService.getData(Constants.USER_DATA)
        );

        this.role = this.localStorageService.getData(Constants.USER_ROLE);
      }
    });
  }

  getCountry() {
    this.authenticationService.getCountry().subscribe(
      (machineData) => {
        this.onCountryChange(machineData['country_code']);
      },
      (err) => {
        this.country = 'US';
        this.onCountryChange(this.country);
      }
    );
  }

  reloadOnCountryChange(value: string) {
    this.onCountryChange(value);
    location.reload();
  }

  onCountryChange(value: string) {
    this.country = value.toUpperCase();
    this.flagCountryCode = `fi-${this.country.toLowerCase()}`;
    this.localStorageService.saveData(
      Constants.COUNTRY,
      value.toLocaleUpperCase()
    );
    this.authenticationService.userCountry.next(value.toLocaleUpperCase());
  }

  onLogoClick() {
    if (
      this.isMenuDisplayed ||
      this.router.url.includes('login') ||
      this.router.url.includes('register')
    ) {
      this.router.navigate(['home']);
    }
  }

  onLogout() {
    this.country = this.localStorageService.getData(Constants.COUNTRY);
    if (this.authenticationService.logout()) {
      this.disableCountry = true;
      this.localStorageService.saveData(Constants.COUNTRY, this.country);
      this.localStorageService.removeData(Constants.FETCH_RESOLVER_DATA);
      this.router.navigate(['/login']);
    }
  }

  onPrivateProfileClick() {
    if(this.role === ROLES.JOB_SEEKER) {
      this.localStorageService.saveData(Constants.FETCH_RESOLVER_DATA, 'true');
    } else {
      this.localStorageService.saveData(Constants.FETCH_RESOLVER_DATA, 'false');
    }
    this.router.navigate(['profile/private']);
  }
  onFreelancerProfileClick() {
    this.localStorageService.saveData(Constants.FETCH_RESOLVER_DATA, 'false');
    this.router.navigate(['profile/freelancer']);
  }
  onPublicProfileClick() {
    this.localStorageService.saveData(Constants.FETCH_RESOLVER_DATA, 'false');
    this.router.navigate(['profile/public']);
  }

  onSettingsClick() {
    this.router.navigate(['/settings']);
  }
}
