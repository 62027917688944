import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BodyComponent } from './body.component';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthenticationService } from '../services/authentication.service';

import { UserNavMenuComponent } from './user-nav-menu/user-nav-menu.component';
import { BodyRoutingModule } from './body-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NoopInterceptor } from '../http-interceptors/http.interceptor';
import { DirectivesModule } from '../utils/directives.module';
import { CommonService } from '../services/common.service';
import { SharedModule } from '../shared/shared.module';
import { HomeComponent } from './common/home/home.component';
import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';
import { ContactUsComponent } from './common/contact-us/contact-us.component';
import { AboutUsComponent } from './common/about-us/about-us.component';
import { GlobalSearchModule } from './common/global-search/global-search.module';
import {RecaptchaFormsModule, RecaptchaModule} from 'ng-recaptcha';


@NgModule({
  declarations: [
    BodyComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    UserNavMenuComponent,
    ContactUsComponent,
    AboutUsComponent,
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BodyRoutingModule,
    DirectivesModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
    GlobalSearchModule,
    SharedModule,
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  exports: [BodyComponent],
  providers: [
    AuthenticationService,
    CommonService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NoopInterceptor,
      multi: true,
    },
  ],
})
export class BodyModule {}
