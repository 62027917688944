import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Menu } from 'src/app/models/menu';
import { AuthenticationService } from 'src/app/services/authentication.service';
import * as $ from 'jquery';
import { LocalStorageService } from 'src/app/services/localStorage.service';
import { Constants } from 'src/app/utils/constants';

@Component({
  selector: 'app-user-nav-menu',
  templateUrl: './user-nav-menu.component.html',
  styleUrls: ['./user-nav-menu.component.scss']
})
export class UserNavMenuComponent {
  isLoggedIn: boolean = false;
  menuList: Menu[] = [];
  selectedIndex: number;
  disableCountry = false;
  country: string | null = null;
  flagCountryCode: string | null = null;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private localStorageService: LocalStorageService
  ) {
    router.events.subscribe((val: any) => {
      if (val instanceof NavigationStart) {
        const route: NavigationStart = val;
        this.selectedIndex = route.url === '/home' ? -1 : this.selectedIndex;
        const currentPath = route.url.substring(1);
        this.menuList.forEach((menu, i) => {
          if (menu.subMenus) {
            menu.subMenus.forEach((submenu) => {
              if (submenu.url === currentPath) {
                this.selectedIndex = i;
              }
            });
          } else {
            if (menu.url === currentPath) {
              this.selectedIndex = i;
            }
          }
        });
      }
    });
  }

  ngAfterViewInit() {
    $('ul.navbar-nav li.dropdown').hover(
      function () {
        $(this).find('.dropdown-menu').stop(true, true).delay(100).fadeIn(150);
      },
      function () {
        $(this).find('.dropdown-menu').stop(true, true).delay(100).fadeOut(150);
      }
    );
  }

  ngOnInit() {
    this.menuList = [];
    if (this.router.url.indexOf('login') !== -1) {
      return;
    }

    this.authenticationService.authenticatedUser$.subscribe((value) => {
      this.isLoggedIn = this.authenticationService.isLoggedIn;

      this.disableCountry = value === undefined || value === null;
      let role = this.localStorageService.getData(Constants.USER_ROLE);
      if (this.isLoggedIn) {
        this.getMenuList(role);

        // After login get country
        let country = this.localStorageService.getData(Constants.COUNTRY);
        this.onCountryChange(country);
      }
    });

    this.loadCountryDropdown();
  }

  getMenuList(role: string): any {
    this.authenticationService.getMenu(role?.toUpperCase()).subscribe((res) => {
      this.menuList = res.data;
    });
  }

  onLinkClick(url: string, index: number): void {
    if (url && url.length) {
      this.router.navigate([url]);
      $('ul.navbar-nav li.dropdown')
        .find('.dropdown-menu')
        .stop(true, true)
        .delay(100)
        .fadeOut(150);
      this.ngAfterViewInit();
      this.selectedIndex = index;
    }
  }

  loadCountryDropdown() {
    if (this.localStorageService.getData(Constants.COUNTRY) !== null) {
      this.country = this.localStorageService.getData(Constants.COUNTRY);
      this.flagCountryCode = `fi-${this.country.toLowerCase()}`;
    } else if (!this.authenticationService.isAuthenticatedUser) {
      this.getCountry();
    }
  }

  onCountryChange(value: string) {
    this.country = value.toUpperCase();
    this.flagCountryCode = `fi-${this.country.toLowerCase()}`;
    this.localStorageService.saveData(
      Constants.COUNTRY,
      value.toLocaleUpperCase()
    );
    this.authenticationService.userCountry.next(value.toLocaleUpperCase());
  }

  getCountry() {
    this.authenticationService.getCountry().subscribe(
      (machineData) => {
        this.onCountryChange(machineData['country_code']);
      },
      (err) => {
        this.country = 'US';
        this.onCountryChange(this.country);
      }
    );
  }
}
