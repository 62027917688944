<div class="container-fluid p-0">
  <div class="row">
    <div class="col-md-12 col-sm-12 col-12 search-banner"></div>
  </div>
</div>
<div class="container">
  <div class="search-ui-position">
    <form [formGroup]="searchFormGroup" class="form-inline">
      <div class="row g-0">
        <div class="row g-0" [ngClass]="colCss">
          <ng-container #dynamicComponent></ng-container>
        </div>

        <div class="col-12 col-md-2" style="z-index: 999">
          <div class="d-flex-botton-position">
            <button
              #btn
              class="form-control search-button"
              [disabled]="!searchFormGroup.valid"
              (click)="onSearchClick()"
            >
              <span>
                <i
                  class="fa fa-search"
                  aria-hidden="true"
                  [title]="buttonLabel"
                ></i>
              </span>
              <span>
                {{ buttonLabel }}
              </span>
            </button>
            <div class="clear-search mt-2">
              <a (click)="onClearResult()">
                <span> Clear Search </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
