import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent {
  data: any;
  constructor(private httpClient: HttpClient) {
    this.getJSON().subscribe(data => {
      this.data = data["AboutUs"];
    });
  }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  public getJSON(): Observable<any> {
    return this.httpClient.get("assets/generalData.json");
  }
}
