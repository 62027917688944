import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { Element, ElementName } from 'src/app/utils/constants';

@Component({
  selector: 'app-date-selection',
  templateUrl: './date-selection.component.html',
  styleUrls: ['./date-selection.component.scss'],
  host: {
    class: 'col-sm-12 col-md z-index-9 mb-1 mb-md-0',
  },
})
export class DateSelectionComponent implements OnInit {
  @Input() element: Element;
  @Input() control: FormControl;

  dateField: Date;
  minDate: Date;
  maxDate: Date;

  constructor(private dataService: DataService) {}

  ngOnInit() {
    //this.control = this.searchFormGroup.get(this.name) as FormControl;

    this.dataService.data$.subscribe((data) => {
      if (this.element.name === ElementName.ToDate && data) {
        this.setMinValueDate(data);
      }
    });
  }

  setMinValueDate(selectedDate: Date): void {
    if (selectedDate === undefined) {
      selectedDate = new Date();
    }

    const newDate = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      selectedDate.getDate()
    );
    this.minDate = newDate;
  }

  onDateValueChange(selectedDate: Date) {
    if (this.element.name === ElementName.FromDate) {
      this.dataService.setData(selectedDate);
    }
  }

  onResetDate() {
    this.control.reset();
  }
}
