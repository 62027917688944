export class AuthenticatedUserModel {
  id: number;
  token: string;
  refreshToken: string;
  firstName: string;
  lastName: string;
  hsId: string;
  email: string;
  roles: Array<string>;
  country: string;
}
