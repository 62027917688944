import { Directive, ElementRef, Input, HostListener } from '@angular/core';

@Directive({
  selector: '[maxLength]',
})
export class CharacterCounterDirective {
  @Input() maxLength: number;
  private counterElement: HTMLSpanElement;

  constructor(private el: ElementRef) {
    this.counterElement = document.createElement('span');
    this.counterElement.id = 'character-counter';
    this.counterElement.classList.add('character-counter');

    if(this.el.nativeElement.parentNode) {
      this.el.nativeElement.parentNode.insertBefore(
        this.counterElement,
        this.el.nativeElement.nextSibling
      );
    }
    this.el.nativeElement.addEventListener('focus', () => {
      this.counterElement.hidden = false;
    });

    this.el.nativeElement.addEventListener('blur', () => {
      this.counterElement.hidden = true;
    });
  }

  @HostListener('input') onInput() {
    this.updateCounter();
    const value = this.el.nativeElement.value;
    if (value.length > this.maxLength) {
      this.el.nativeElement.value = value.substring(0, this.maxLength);
      this.updateCounter();
    }
  }

  private updateCounter() {
    const value = this.el.nativeElement.value;
    this.counterElement.textContent = `${value.length}/${this.maxLength}`;
  }
}
