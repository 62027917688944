import { Injectable } from "@angular/core";

import { Subject, catchError, of } from "rxjs";
import { BaseService } from "../base-service";
import { URLS } from "src/app/utils/URLS";

@Injectable({
    providedIn: 'root'
  })
  export class ContactResolverService  {

    constructor(private baseService: BaseService) {}

    resolve() {
        const observer = new Subject<any>();
        this.baseService.httpPost(URLS.countryData, {"boolParam": true}).pipe(catchError(error => of(error))).subscribe(
            (data)=>{
                observer.next(data.data);
                observer.complete();
            }
        );
        return observer.asObservable();
    }
  }