<form
  (keyup.enter)="onApplyFilters()"
  [formGroup]="searchForm"
  class="form-inline"
>
  <div class="accordion border">
    <!--  REFINE RESULTS -->
    <!-- Tablet & Desktop View -->
    <div class="accordion-item d-none d-sm-block d-md-block">
      <h2 class="accordion-header" id="refineResult">
        <div class="accordion-title">
          REFINE RESULTS
          <div (click)="onApplyFilters()">
            <i class="fa fa-filter cursor-pointer"></i>
          </div>
        </div>
      </h2>
      <div>
        <ng-container *ngTemplateOutlet="refineResultAccordion"></ng-container>
      </div>
    </div>

    <!-- Mobile View -->
    <div class="accordion-item d-block d-sm-none d-md-none">
      <h2 class="accordion-header" id="refineResult">
        <div
          class="accordion-title accordion-button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseRefineResult"
          aria-expanded="false"
          aria-controls="collapseRefineResult"
        >
          <strong>REFINE RESULTS</strong>

          <div class="ms-3" (click)="onApplyFilters()">
            <i class="fa fa-filter cursor-pointer"></i>
          </div>
        </div>
      </h2>
      <div
        id="collapseRefineResult"
        class="accordion-collapse collapse"
        aria-labelledby="refineResult"
        data-bs-parent="#accordion"
      >
        <ng-container *ngTemplateOutlet="refineResultAccordion"></ng-container>
      </div>
    </div>

    <ng-template #refineResultAccordion>
      <!-- DISTANCE -->
      <div *ngIf="country === 'US'" class="accordion-item">
        <h2 class="accordion-header" id="headingOne">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseDistance"
            aria-expanded="true"
            aria-controls="collapseDistance"
          >
            DISTANCE
          </button>
        </h2>
        <div
          id="collapseDistance"
          class="accordion-collapse collapse"
          aria-labelledby="headingOne"
          data-bs-parent="#accordion"
        >
          <div class="accordion-body">
            <div>
              <select
                id="distance_selector"
                class="form-select form-control custom-select w-100"
                name="radius"
                formControlName="selectedDistance"
              >
                <option *ngFor="let distance of distances" [ngValue]="distance">
                  {{ distance }}
                </option>
              </select>
            </div>
            <div class="mt-2">
              <input
                name="postal_code"
                id="postal_code"
                formControlName="postal_code"
                type="text"
                placeholder="Enter zip code"
                maxlength="5"
                minlength="5"
                class="form-control w-100"
                autocomplete="off"
              />
              <div
                *ngIf="f['postal_code']?.errors && f['postal_code']?.touched"
                class="text-danger p-1"
              >
                Zip code must be of 5 digits.
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- POSTED  -->
      <div *ngIf="showPosted" class="accordion-item">
        <h2 class="accordion-header" id="headingTwo">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapsePosted"
            aria-expanded="true"
            aria-controls="collapsePosted"
          >
            POSTED
          </button>
        </h2>
        <div
          id="collapsePosted"
          class="accordion-collapse collapse"
          aria-labelledby="headingTwo"
          data-bs-parent="#accordion"
        >
          <div class="accordion-body">
            <div class="list-group">
              <label
                class="fw-normal"
                *ngFor="let item of postedDateFilter.list"
                [class.selected-radio]="
                  searchForm.value.radioSelected === item.actualName
                "
              >
                <input
                  type="radio"
                  formControlName="radioSelected"
                  name="radioSelected"
                  value="{{ item.actualName }}"
                />
                <span class="radio-text">{{ item.name }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <!-- multiselect-panel -->
      <ng-container *ngFor="let mstObj of masterFilterList; let i = index">
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              [attr.data-bs-target]="'#' + getTitle(mstObj)"
              aria-expanded="true"
              [attr.aria-controls]="getTitle(mstObj)"
            >
              {{ mstObj.title | uppercase }}
            </button>
          </h2>
          <div
            [attr.id]="getTitle(mstObj)"
            class="accordion-collapse collapse"
            data-bs-parent="#accordion"
          >
            <div class="accordion-body">
              <app-multiselect-panel
                [params]="mstObj.list"
                [key]="mstObj.key"
                (checkBoxClicked)="onCheckBoxClicked(mstObj.key, $event)"
              ></app-multiselect-panel>
            </div>
          </div>
        </div>
      </ng-container>
      <!-- footer -->
      <div class="accordion-footer px-3 py-3 m-0">
        <div class="row g-0 align-items-end">
          <div class="col-6">
            <button
              class="btn-link"
              type="button"
              (click)="clearFilterData(); searchForm.reset()"
            >
              <span>Clear filters</span>
            </button>
            <i
              class="fa fa-eraser cursor-pointer"
              (click)="clearFilterData(); searchForm.reset()"
            ></i>
          </div>
          <div class="col-6 text-end">
            <button
              type="button"
              class="btn-refine"
              [disabled]="searchForm.invalid"
              (click)="onApplyFilters()"
              (keyup.enter)="onApplyFilters()"
            >
              Refine Search <i class="fa fa-filter"></i>
            </button>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</form>
