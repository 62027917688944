import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import {
  DateSearch,
  ListingObject,
  LocationSearch,
  MultiSelectOptions,
  SolrFilters,
  SolrSearch,
} from 'src/app/models/solrSearch';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { SolrSearchService } from 'src/app/services/solarSearch.service';
import { Utils } from 'src/app/utils/utils';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {
  searchForm: FormGroup;
  filterListingSequence: ListingObject[];
  distances: string[];

  zip: string;
  country: string = '';
  solrSearch: SolrSearch = new SolrSearch();
  myDate = new Date();
  locationSearchDTO: LocationSearch = new LocationSearch();
  dateSearchDTO: DateSearch = new DateSearch();

  searchData: any = {};
  _filters: any;
  _appliedSearchData: SolrSearch;

  masterFilterList: SolrFilters[] = new Array<SolrFilters>();
  tempMasterFilterList: SolrFilters[] = new Array<SolrFilters>();
  postedDateFilter: SolrFilters = new SolrFilters();

  @Output() applyFilterEvent = new EventEmitter<SolrSearch>();
  @Output() clearAllFilterEvent = new EventEmitter();

  @Input() showPosted = false;

  @Input() public set appliedSearchData(val: SolrSearch) {
    this.setAppliedSearchData(val);
  }

  @Input() public set filter(val: any) {
    this.setFilter(val);
  }
  @Input() fieldName: string = 'posted_date';

  public get appliedSearchData(): SolrSearch {
    return this._appliedSearchData;
  }

  constructor(
    public solrSearchService: SolrSearchService,
    private toastrService: ToastrService,
    private authService: AuthenticationService
  ) {
    this.country = localStorage.getItem('Country');
    this.authService.userCountry.subscribe((res) => {
      if (res) {
        this.country = res;
      }
    });
    this.registerForm();
    this.getFilterList();
    this.getDistances();
  }

  getTitle(obj: SolrFilters): string {
    // return 'collapse' + obj.title.split(' ').join('_');
    return obj.title.split(' ').join('_');
  }

  setFilter(val: any) {
    const previousPage = localStorage.getItem('PreviousPage');
    if (previousPage === 'true') {
      this.searchData = {};
      localStorage.removeItem('checkedValue');
    }
    localStorage.removeItem('PreviousPage');
    this._filters = val;
    this.retriveMasterFilterListData();
  }

  setAppliedSearchData(val: any) {
    if (val) {
      const { locationSearchDTO, dateSearchDTO, searchParams } = val;
      this._appliedSearchData = val;
      if (locationSearchDTO && locationSearchDTO.distance) {
        const distance = locationSearchDTO.distance.toString();
        const foundDistance = this.distances.find((d) => d.includes(distance));
        this.searchForm.patchValue({
          selectedDistance: foundDistance || this.distances[0],
        });
      }
      if (locationSearchDTO && locationSearchDTO.postal_code) {
        this.searchForm.patchValue({
          postal_code: locationSearchDTO.postal_code,
        });
      }
      if (dateSearchDTO && dateSearchDTO.from && dateSearchDTO.to) {
        const time =
          new Date(dateSearchDTO.to).getTime() -
          new Date(dateSearchDTO.from).getTime();
        const days = time / (1000 * 3600 * 24);
        const radioSelected =
          days === 7
            ? 'NOW-7DAY/DAY'
            : days === 15
            ? 'NOW-7DAY/DAY'
            : days === 30
            ? 'NOW-1MONTH/MONTH'
            : null;
        if (radioSelected) {
          this.searchForm.patchValue({ radioSelected });
        }
      }
      if (searchParams) {
        this.searchData = searchParams;
      }
    }
  }

  ngOnInit() {}

  retriveMasterFilterListData() {
    this.tempMasterFilterList = [];
    if (this.showPosted) {
      this.postedDateFilter = this.getPOSTED();
    }
    for (const key in this._filters) {
      if (this._filters.hasOwnProperty(key) && this.isDisplayCity(key)) {
        const solrFilterObj = new SolrFilters();
        const element = this._filters[key];
        solrFilterObj.title = this.getKey(key);
        solrFilterObj.key = key;
        solrFilterObj.list = this.getList(element);
        // Set selected values in Search Data
        solrFilterObj.list.forEach((element) => {
          if (element.isSelected) {
            this.createSearchData(solrFilterObj.key, element);
          }
        });
        this.tempMasterFilterList.push(solrFilterObj);
      }
    }
    this.rearrangeMasterList();
  }

  rearrangeMasterList(): void {
    this.masterFilterList = [];
    for (const kv of this.filterListingSequence) {
      const tempSolrFilterObj = this.tempMasterFilterList.find(
        (obj) => obj.key.toUpperCase() === kv.value.toUpperCase()
      );
      if (tempSolrFilterObj) {
        tempSolrFilterObj.title = kv.displayName;
        this.masterFilterList.push(tempSolrFilterObj);
      }
    }
  }

  getPOSTED(): SolrFilters {
    const solrFilterObj: SolrFilters = new SolrFilters();
    solrFilterObj.key = 'posted_date';
    solrFilterObj.title = 'Posted';
    solrFilterObj.list = new Array<MultiSelectOptions>();
    const today: MultiSelectOptions = new MultiSelectOptions();
    today.name = 'Last 7 Days';
    today.actualName = 'NOW-7DAY/DAY';
    today.isSelected = false;
    solrFilterObj.list.push(today);

    const lst7Days: MultiSelectOptions = new MultiSelectOptions();
    lst7Days.name = 'Last 15 Days';
    lst7Days.actualName = 'NOW-15DAY/DAY';
    lst7Days.isSelected = false;
    solrFilterObj.list.push(lst7Days);

    const lst30Days: MultiSelectOptions = new MultiSelectOptions();
    lst30Days.name = 'Last 30 Days';
    lst30Days.actualName = 'NOW-1MONTH/MONTH';
    lst30Days.isSelected = false;
    solrFilterObj.list.push(lst30Days);

    return solrFilterObj;
  }

  getKey(key: string): string {
    const arr: string[] = key.split('_');
    return arr.map(Utils.sentenceCase).join(' ');
  }

  getList(element): MultiSelectOptions[] {
    const returnVal: MultiSelectOptions[] = [];
    for (const key in element) {
      if (element.hasOwnProperty(key)) {
        const count = element[key];

        returnVal.push({
          id: 0,
          name: Utils.capitalFirstLetter(key) + ' (' + count + ')',
          actualName: key,
          isSelected: false,
          sortOrder: 0,
        });
      }
    }
    return returnVal;
  }

  isDisplayCity(key: string): boolean {
    if (key === 'city_s' || key === 'city') {
      return (
        this._appliedSearchData &&
        this._appliedSearchData.searchParams !== undefined &&
        (this._appliedSearchData.searchParams.hasOwnProperty('state_s') ||
          this._appliedSearchData.searchParams.hasOwnProperty('state'))
      );
    } else {
      return true;
    }
  }

  onApplyFilters() {
    if (
      !this.searchForm.value.postal_code &&
      this.searchForm.value.selectedDistance
    ) {
      this.toastrService.warning('Please enter Zip Code');
      return;
    } else if (
      this.searchForm.value.postal_code &&
      !this.searchForm.value.selectedDistance
    ) {
      this.toastrService.warning('Please select Exact Location');
    }

    this.solrSearch = new SolrSearch();
    this.locationSearchDTO = new LocationSearch();
    this.dateSearchDTO = new DateSearch();
    this.appliedSearchData.cursorMark = '*';
    this.solrSearch = this.appliedSearchData;
    if (this.searchForm.value.radioSelected) {
      this.dateSearchDTO = new DateSearch();
      this.dateSearchDTO.fieldName = this.fieldName;
      if (this.searchForm.value.radioSelected === 'NOW-15DAY/DAY') {
        this.myDate = new Date();
        this.dateSearchDTO.to = this.myDate.toISOString();
        this.myDate.setDate(this.myDate.getDate() - 15);
        this.dateSearchDTO.from = this.myDate.toISOString();
      }
      if (this.searchForm.value.radioSelected === 'NOW-7DAY/DAY') {
        this.myDate = new Date();
        this.dateSearchDTO.to = this.myDate.toISOString();
        this.myDate.setDate(this.myDate.getDate() - 7);
        this.dateSearchDTO.from = this.myDate.toISOString();
      }
      if (this.searchForm.value.radioSelected === 'NOW-1MONTH/MONTH') {
        this.myDate = new Date();
        this.dateSearchDTO.to = this.myDate.toISOString();
        this.myDate.setDate(this.myDate.getDate() - 30);
        this.dateSearchDTO.from = this.myDate.toISOString();
      }
      this.solrSearch.dateSearchDTO = this.dateSearchDTO;
    }
    if (
      this.solrSearch !== undefined &&
      this.searchForm.value.postal_code !== '' &&
      this.searchForm.value.postal_code != null &&
      !!this.searchForm.value.selectedDistance
    ) {
      //} && this.searchForm.value.selectedDistance !== 'Exact location only') {
      this.locationSearchDTO = new LocationSearch();
      if (this.searchForm.value.selectedDistance !== 'Exact location only') {
        this.locationSearchDTO.distance = this.searchForm.value.selectedDistance
          .match(/\d+/g)
          .map(Number)[0];
      } else {
        this.locationSearchDTO.distance = 1;
      }

      this.locationSearchDTO.postal_code = this.searchForm.value.postal_code;
      this.solrSearch.locationSearchDTO = this.locationSearchDTO;
      if (this.searchData.state_s) {
        delete this.searchData.state_s;
      }
      if (this.searchData.location) {
        delete this.searchData.location;
      }
      this.solrSearch.searchParams = this.searchData;
    } else {
      // Removed 'Exact location only' parameter to resolve bug 1493
      if (
        !!this.searchForm.value.selectedDistance ||
        this.searchForm.value.postal_code === ''
      ) {
        delete this.solrSearch.locationSearchDTO;
      }
      if (Object.keys(this.searchData).length > 0) {
        this.solrSearch.searchParams = this.searchData;
      }
    }
    for (let ms in this.searchData) {
      if (this.searchData[ms].length === 0) {
        delete this.searchData[ms];
      }
    }
    localStorage.setItem('checkedValue', JSON.stringify(this.searchData));
    this.solrSearch.pageMarks = ['*'];
    this.applyFilterEvent.emit(this.solrSearch);
  }

  onCheckBoxClicked(mstName: string, event: MultiSelectOptions): void {
    if (mstName !== undefined) {
      this.createSearchData(mstName, event);
    }
  }

  createSearchData(mstName: string, obj: MultiSelectOptions): any {
    const array = Object.getOwnPropertyNames(this.searchData);
    if (array.length > 0) {
      if (this.searchData.hasOwnProperty(mstName)) {
        this.updateExistingProperty(mstName, obj);
      } else {
        this.addNewProperty(mstName, obj.actualName);
      }
    } else {
      this.addNewProperty(mstName, obj.actualName);
    }
  }

  updateExistingProperty(key: string, obj: any): void {
    const element = this.searchData[key];
    const idx = this.searchData[key].indexOf(obj.actualName);

    if (obj.isSelected) {
      if (idx === -1) {
        this.searchData[key].push(obj.actualName);
      }
    } else if (key === 'gender' || key === 'city_s') {
      this.searchData[key].splice(idx, 1);
    } else {
      this.searchData[key] = this.searchData[key].filter((item, index) => {
        return item !== obj.actualName && index === idx;
      });
    }

    this.searchData[key] = [...new Set(this.searchData[key])];
  }

  addNewProperty(key, value) {
    if (!!!this.searchData[key]) {
      this.searchData[key] = new Array<String>();
    }
    this.searchData[key].push(value);
  }

  clearFilterData(): void {
    this.searchData = {};
    localStorage.removeItem('checkedValue');
    localStorage.removeItem('storeObj');
    delete this.solrSearch.searchParams;
    delete this.solrSearch.locationSearchDTO;
    delete this.solrSearch.dateSearchDTO;
    this.solrSearch.pageMarks = ['*'];
    this.searchForm.reset();
    this.clearAllFilterEvent.emit();
    this.solrSearchService.clearFilter();
  }

  getFilterList() {
    this.filterListingSequence = [
      { key: 1, value: 'job_category_name', displayName: 'Job Category' },
      { key: 2, value: 'looking_for_job', displayName: 'Looking For Job' },
      { key: 3, value: 'gender', displayName: 'Gender' },
      {
        key: 4,
        value: 'highest_qualification',
        displayName: 'Highest Qualification',
      },
      { key: 5, value: 'work_experience', displayName: 'Work Experience' },
      {
        key: 6,
        value: 'job_experience_level',
        displayName: 'Experience Level',
      },
      { key: 7, value: 'employment_type', displayName: 'Employment Type' },
      { key: 8, value: 'immigration_type', displayName: ' Immigration Type' },
      { key: 9, value: 'remote', displayName: 'Remote' },
      { key: 10, value: 'industry_type_code', displayName: 'Industry Type' },
      { key: 10, value: 'is_preferred_emp', displayName: 'Preferred Employer' },
      { key: 10, value: 'is_visa_spons', displayName: 'Work Visa' },
      { key: 11, value: 'school_type_code', displayName: 'School Type' },
      {
        key: 12,
        value: 'school_classification_type_code',
        displayName: 'School Classification',
      },
      { key: 13, value: 'offer_category_name', displayName: 'Offer Category' },
      { key: 14, value: 'state_s', displayName: 'Location' },
      { key: 15, value: 'city_s', displayName: 'City' },
    ];
  }

  getDistances() {
    this.distances = [
      'Exact location only',
      'within 5 miles',
      'within 15 miles',
      'within 25 miles',
      'within 50 miles',
      'within 100 miles',
    ];
  }

  get f() {
    return this.searchForm.controls;
  }

  registerForm() {
    this.searchForm = new FormGroup({
      selectedDistance: new FormControl(''),
      radioSelected: new FormControl(''),
      postal_code: new FormControl('', [
        Validators.pattern('^(0|[1-9][0-9]*)$'),
      ]),
    });
  }
}
