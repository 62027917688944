import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/localStorage.service';
import { Constants, REGISTER_TABS } from 'src/app/utils/constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  @Input() isLoggedIn: boolean = false;
  userRole: string = '';
  currentyear: number = new Date().getFullYear();
  country: string = '';
  REGISTER_TABS = REGISTER_TABS;

  constructor(
    private commonService: CommonService,
    private authenticationService: AuthenticationService,
    private router: Router,
    public localStorageService: LocalStorageService
  ) {
    this.authenticationService.userCountry.subscribe(res => {
      if(res) {
        this.country = res;
      }
    });
  }

  ngOnInit() {
    if (this.localStorageService.getData(Constants.COUNTRY) !== null) {
      this.country = this.localStorageService.getData(Constants.COUNTRY);
    }
    const node = document.createElement('script');
    node.src =
      'https://seal.godaddy.com/getSeal?sealID=65XofbDP0zPCMZPqhexV48p5nKmDzffSXvHwwGwoWI0QsfLGgQo7btPg7yTn';
    node.type = 'text/javascript';
    node.async = true;
    document.getElementById('siteseal').appendChild(node);
  }

  signIn(role: string) {
    if (role === REGISTER_TABS.JOBSEEKER) {
      this.authenticationService.signInJobseeker.next(true);
    } else {
      this.authenticationService.signInJobseeker.next(false);
    }
    this.router.navigate(['/register']);
  }

  onClickPolicyTerms(str) {
    this.commonService.openManual(str + this.country).subscribe((res) => {
      window.open(res.data.url, '_blank');
    });
  }
}
