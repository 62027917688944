<nav class="bg-header d-print-none">
  <div class="container">
    <!-- Header row -->
    <div class="row g-0 mh-90 d-flex justify-content-start align-items-center">
      <!-- Logo -->
      <div class="col-6 col-md-6 col-lg-6">
        <a
          class="navbar-brand profile-logo-mob float-left w-50 d-flex h-100 align-items-center h-110"
          (click)="onLogoClick()"
          ><img src="assets/images/hyring-spree-logo.png" class="img-logo"
        /></a>
      </div>
      <div class="col-4 col-md-3 col-lg-3 d-none d-lg-block">
        <div class="d-flex justify-content-end align-items-center">
          <!-- Country -->
          <div class="country pe-2">
            <span>Country</span>
          </div>

          <!-- Country Dropdown -->
          <div
            class="dropdown pe-2"
            [ngClass]="{ 'no-pointer': disableHeader }"
          >
            <div
              class="btn btn-default dropdown-toggle dropdown-wrap"
              [ngClass]="disableCountry ? '' : 'dropdown-wrap-disabled'"
              type="button"
              id="menu1"
              data-toggle="dropdown"
            >
              <span class="fi fis flag" [ngClass]="flagCountryCode"></span>
              <span class="country ml-2" *ngIf="country === 'IN'">India</span>
              <span class="country ml-2" *ngIf="country === 'US'">USA</span>
              <span class="country ml-2" *ngIf="country === 'CA'">Canada</span>
              <span class="country ml-2" *ngIf="country === 'GB'">UK</span>
              <span class="country ml-2" *ngIf="country === 'AU'"
                >Australia</span
              >
            </div>
            <ul
              class="dropdown-menu custom-menu"
              [style.display]="disableCountry ? '' : 'none'"
              role="menu"
              aria-labelledby="menu1"
            >
              <li role="presentation" (click)="reloadOnCountryChange('au')">
                <span class="fi fi-au fis flag"></span
                ><span class="country ml-2">Australia</span>
              </li>
              <li role="presentation" (click)="reloadOnCountryChange('in')">
                <span class="fi fi-in fis flag"></span
                ><span class="country ml-2">India</span>
              </li>
              <li role="presentation" (click)="reloadOnCountryChange('gb')">
                <span class="fi fi-gb fis flag"></span
                ><span class="country ml-2">UK</span>
              </li>
              <li role="presentation" (click)="reloadOnCountryChange('us')">
                <span class="fi fi-us fis flag"></span
                ><span class="country ml-2">USA</span>
              </li>
              <li role="presentation" (click)="reloadOnCountryChange('ca')">
                <span class="fi fi-ca fis flag"></span
                ><span class="country ml-2">Canada</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- Sign IN - Sign UP Buttons -->
      <div class="col-6 col-md-6 col-lg-3">
        <div class="d-flex justify-content-end align-items-start">
          <!-- Anonymous User -->
          <ng-container *ngIf="!authenticationService.isLoggedIn">
            <!-- Sign IN -->
            <button
              class="btn pe-2 me-1"
              routerLinkActive="active"
              routerLink="/login"
              [ngClass]="{ active: !applyClass }"
            >
              SIGN IN
            </button>

            <!-- Sign UP -->
            <button
              class="btn pe-2"
              routerLinkActive="active"
              routerLink="/register"
              [ngClass]="{ active: applyClass }"
            >
              SIGN UP
            </button>
          </ng-container>

          <!-- Logged In User -->
          <ng-container *ngIf="authenticationService.isLoggedIn">
            <div
              id="userDropdown"
              [ngClass]="(loggedInUser.firstName.length + loggedInUser.lastName.length) <  30 ? 'user-info' : 'user-info-big'"
              class="dropdown"
              [ngClass]="{ 'no-pointer': disableHeader }"
            >
              <a class="dropdown-toggle dropend d-flex">
                <img
                  src="assets/images/user-icon.png"
                  class="profile-img me-1"
                />
                <div [ngClass]="(loggedInUser.firstName.length + loggedInUser.lastName.length) < 30 ? 'user-name' : 'user-name user-name-big'" class="loggedInUser">
                  {{ loggedInUser.firstName }}
                </div>
                
                <span [ngClass]="(loggedInUser.firstName.length + loggedInUser.lastName.length) < 30 ? 'user-name' : 'user-name user-name-big'" class="loggedInUser d-none d-sm-inline-block">
                  {{ loggedInUser.lastName }}
                </span>
              </a>
              <ul class="dropdown-menu">
                <li>
                  <a (click)="onPrivateProfileClick()">Private Profile</a>
                </li>
                <li>
                  <a
                    *ngIf="role && role.toUpperCase() === 'JOB_SEEKER'"
                    (click)="onFreelancerProfileClick()"
                    >Freelancer Profile</a
                  >
                </li>
                <li>
                  <a
                    *ngIf="role && role.toUpperCase() === 'JOB_SEEKER'"
                    (click)="onPublicProfileClick()"
                    >Public Profile</a
                  >
                </li>
                <li>
                  <a
                    (click)="onSettingsClick()"
                    >Settings</a
                  >
                </li>
                <li>
                  <a
                    data-bs-toggle="modal"
                    data-bs-target="#logoutConfirmation"
                  >
                    Log Out
                  </a>
                </li>
              </ul>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</nav>
<!-- Modal -->
<div
  class="modal"
  id="logoutConfirmation"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="logoutConfirmationLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="logoutConfirmationLabel">Logout</h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <p>Are you sure you want to logout?</p>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          (click)="onLogout()"
          data-bs-dismiss="modal"
        >
          Logout
        </button>
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
