import { NgModule } from '@angular/core';
import { PhoneMaskDirective } from './phone-mask.directive';
import { CommonModule } from '@angular/common';
import { CharacterCounterDirective } from './character-counter.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [PhoneMaskDirective, CharacterCounterDirective],
  exports: [PhoneMaskDirective, CharacterCounterDirective],
})
export class DirectivesModule {}
