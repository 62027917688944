import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { SolrSearchService } from 'src/app/services/solarSearch.service';
import { Element } from 'src/app/utils/constants';

@Component({
  selector: 'app-dropdown-selection',
  templateUrl: './dropdown-selection.component.html',
  styleUrls: ['./dropdown-selection.component.scss'],
  host: {
    class: 'col-sm-12 col-md z-index-9 mb-1 mb-md-0',
  },
})
export class DropdownSelectionComponent implements OnInit {
  @Input() element: Element;
  @Input() control: FormControl;

  dataSource: any[];

  constructor(private solrSearchService: SolrSearchService) {}

  ngOnInit() {
    this.control.setValue(null);
    this.solrSearchService.getResults(this.element.api, '').subscribe((res) => {
      this.dataSource = res['data'];
    });
  }

  onResetData() {
    this.control.setValue(null);
    this.control.reset();
  }
}
