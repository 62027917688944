import { Constants } from 'src/app/utils/constants';
import { LocalStorageService } from 'src/app/services/localStorage.service';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Result } from '../models/result';
import { AuthenticationService } from '../services/authentication.service';
import { APP_CONFIG } from '../utils/constants';

@Injectable()
export class NoopInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authservice: AuthenticationService,
    private toastrService: ToastrService,
    private localStorageService: LocalStorageService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let changedReq = req.clone();
    if (req.url.indexOf('ipapi') !== -1 || req.url.indexOf('login') !== -1) {
      changedReq = req.clone({
        headers: req.headers,
      });
    } else {
      let token;
      let headers = req.headers;
      let tokenValue = this.localStorageService.getData(APP_CONFIG.AUTH_TOKEN);

      if (tokenValue !== null) {
        token =
          tokenValue.indexOf('Bearer ') !== -1
            ? tokenValue
            : 'Bearer ' + tokenValue;
      }

      if (token !== undefined && token !== null) {
        headers = headers.set('Authorization', token); // immutable headers object.
      }

      headers = headers.set(
        'Country',
        this.localStorageService.getData(Constants.COUNTRY) === null
          ? ''
          : this.localStorageService.getData(Constants.COUNTRY)
      );

      changedReq = req.clone({
        headers: headers,
      });
    }

    return next.handle(changedReq).pipe(
      map((res: any) => {
        if (res && res['status'] && res['status'] !== 200) {
          this.showError(res['error']);
        }
        return res;
      }),
      catchError((err) => {
        return this.handleError(err, changedReq, null);
      })
    );
  }

  private handleError(err, changedReq: HttpRequest<any>, body) {
    const result: Result = err.error as Result;
    if (err && !err.error && err.status === 401) {
      this.authservice.logout();
      this.router.navigate(['/login']);
    } else if (err && err.error) {
      if (err.status === 403) {
        this.authservice.logout();
        if (!this.router.url.includes('/login')) {
          this.router.navigate(['/login'], {
            queryParams: { returnUrl: location.href },
          });
        }
      }
      if (result.header) {
        if (result.header.code === 409) {
          if (
            result.header !== undefined &&
            result.header.message !== '' &&
            !result.data
          ) {
            this.showError({ message: result.header.message, title: 'Error' });
          } else {
            //return throwError(err);
          }
        }
        if (result.header.code === 401 || result.header.code === 403) {
          this.authservice.logout();
          if (!this.router.url.includes('/login')) {
            this.router.navigate(['/login'], {
              queryParams: { returnUrl: location.href },
            });
          }
        }
      }
    } else if (result && result.header.code === 401) {
      this.refreshToken(changedReq.url, body);
      this.reloadAPI(changedReq.url, body);
    }
    return throwError(err);
  }

  private refreshToken(x, y) {
    this.authservice.refreshToken().subscribe(
      (res) => {
        this.reloadAPI(x, y);
      },
      (error) => {}
    );
  }

  private reloadAPI(x, y) {
    this.authservice.reloadAPI(x, y).subscribe(
      (res) => {},
      (error) => {}
    );
  }

  private showError(error: any) {
    if (error !== null) {
      if (error.message !== undefined) {
        this.toastrService.error(error.message, error.title, {
          timeOut: 10000,
          progressBar: true,
          closeButton: true,
        });
      } else {
        this.toastrService.error(error.header.message, error.title, {
          timeOut: 10000,
          progressBar: true,
          closeButton: true,
        });
      }
    }
  }
}
