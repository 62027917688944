<div class="mb-3">
  <app-home-search-solr
    [searchElement]="searchElement"
    (searchResultEvent)="onSearchResultResponse($event)"
    (clearResultEvent)="onClearResultResponse()"
  ></app-home-search-solr>
</div>
<!-- If result not found then show this section -->
<app-no-result-found
  *ngIf="!showResults"
  message="We can't find any jobs matching your search criteria, Please refine
your search criteria and try again."
>
</app-no-result-found>

<div class="container" *ngIf="showResults">
  <div class="row">
    <div class="col-12 col-md-4">
      <app-filter
        *ngIf="filters"
        [showPosted]="true"
        [appliedSearchData]="solrSearch"
        [filter]="filters"
        (applyFilterEvent)="onSearchResults($event)"
        (clearAllFilterEvent)="onClearFilter()"
      ></app-filter>
    </div>
    <div class="col-12 col-md-8 mt-4">
      <app-pagination
        [templateFromParent]="jobdetails"
        [responseData]="responseData"
        [pagination]="pagination"
        [solrSearch]="solrSearch"
        [sortOptions]="sortOptions"
        [type]="'Jobs'"
        (searchResultEvent)="onSearchResults($event)"
      >
        <ng-template #jobdetails let-job>
          <div class="row align-items-center">
            <div class="col-12 col-sm-6 col-md-8">
              <a
                class="card-view-header"
                (click)="onRedirectToViewJob(job.jobCode)"
              >
                <h4 [innerHTML]="job?.title"></h4>
              </a>
              <p class="card-view-body text-gray">
                {{ job?.companyNamesDTO?.name || "Not Available" }}
              </p>
              <p class="card-view-body text-gray">
                Posted Date:
                {{ job?.postedDate | date : "dd MMM yy" || "Not Available" }}
              </p>
            </div>
            <div class="col-12 col-sm-6 col-md-4">
              <h4 class="card-view-subtitle text-sm-end">
                {{ job?.city }},{{ job?.stateCode }}
              </h4>
              <span>
                <a
                  class="card-view-button"
                  (click)="onRedirectToViewJob(job.jobCode)"
                >
                  View Job
                </a>
              </span>
            </div>
          </div>
        </ng-template>
      </app-pagination>
    </div>
  </div>
</div>
