<app-header></app-header>
<ng-container>
  <app-user-nav-menu *ngIf="!disableHeader"></app-user-nav-menu>
</ng-container>
<div class="float-none mt-1">
  <router-outlet></router-outlet>
</div>
<app-footer [isLoggedIn]="isLoggedIn"></app-footer>

<!-- Spinner -->
<div *ngIf="showHideSpinner" class="spinner-overlay">
  <!-- <div class="spinner1"></div> -->
  <div class="dot-pulse"></div>
</div>
