import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
  pure:true,
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], field: string, value: string): any[] {
    if (!items || !value) {
      return items;
    }
    const filterValue = value.toLowerCase();
    return items.filter((item) =>
      item[field].toLowerCase().includes(filterValue)
    );
  }
}
