import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DateSearch, SolrSearch } from 'src/app/models/solrSearch';
import { SolrSearchService } from 'src/app/services/solarSearch.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { Element, SearchElement, ElementType } from 'src/app/utils/constants';
import { AutoCompleteTextComponent } from '../auto-complete-text/auto-complete-text.component';
import { DateSelectionComponent } from '../date-selection/date-selection.component';
import { DropdownSelectionComponent } from '../dropdown-selection/dropdown-selection.component';
import { FreeTextComponent } from '../free-text/free-text.component';

@Component({
  selector: 'app-search-solr',
  templateUrl: './search-solr.component.html',
  styleUrls: ['./search-solr.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchSolrComponent implements OnInit, AfterViewInit {
  @Input() searchElement: SearchElement;
  searchFormGroup: FormGroup;

  @ViewChild('dynamicComponent', { read: ViewContainerRef, static: false })
  dynamicComponent: ViewContainerRef;

  elements: Element[];
  buttonLabel: string;
  searchApi: string;
  colCss: string;
  currenctCursorMark = '*';
  zipCodeRegex: RegExp = new RegExp('^[0-9]{5}(?:-[0-9]{4})?$');
  dateSearchDTO: DateSearch = new DateSearch();
  solrSearch: SolrSearch;

  @Input() fieldName: string = 'posted_date';

  @Output() searchResultEvent = new EventEmitter<any>();
  @Output() clearResultEvent = new EventEmitter<any>();

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private spinnerService: SpinnerService,
    private toastrService: ToastrService,
    private solrSearchService: SolrSearchService
  ) {}

  ngOnInit() {
    this.elements = this.searchElement.elements;
    this.buttonLabel = this.searchElement.buttonLabel;
    this.searchApi = this.searchElement.searchApi;

    // Create a dynamic form control for each element
    const group = {};
    this.elements.forEach((element) => {
      group[element.name] = new FormControl(
        null,
        element.onlyNumber ? Validators.pattern(/^[0-9]*$/) : null
      );
    });

    this.searchFormGroup = new FormGroup(group);
    this.colCss = this.getCalcColCss();
  }

  ngAfterViewInit() {
    this.elements.forEach((element: Element, index: number) => {
      element.itemIndex = index;
      let componentFactory = this.getComponentType(element);

      // Create the component and add it to the dynamicComponent view container
      const componentRef =
        this.dynamicComponent.createComponent(componentFactory);
      componentRef.setInput(
        'control',
        this.searchFormGroup.get(element.name.toString())
      );
      componentRef.setInput('element', element);

      componentRef.changeDetectorRef.detectChanges();
    });
  }

  getComponentType(element: Element) {
    let componentFactory;
    switch (element.type) {
      case ElementType.FreeText:
        componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            FreeTextComponent
          );
        break;
      case ElementType.AutoCompleteText:
        componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            AutoCompleteTextComponent
          );
        break;
      case ElementType.DropdownSelection:
        componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            DropdownSelectionComponent
          );
        break;
      case ElementType.DateSelection:
        componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            DateSelectionComponent
          );
        break;
      default:
        componentFactory = null;
        throw new Error(`Invalid element type: ${element.type}`);
    }

    return componentFactory;
  }

  onSearchClick() {
    if (!this.isValidForm) {
      this.toastrService.warning('Please enter search criteria');
      return;
    }

    // TODO: Validate from date & to date
    const fromDate = this.searchFormGroup.value.fromDate;
    const toDate = this.searchFormGroup.value.toDate;
    if (fromDate) {
      if (fromDate > toDate) {
        this.toastrService.warning('Please enter valid date criteria');
        return;
      }
    }

    this.generateSearchData(this.searchFormGroup.value);
  }

  // TODO: function to generate the search data based on dynamic form group
  generateSearchData(formData: any) {
    this.solrSearch = new SolrSearch();
    this.solrSearch.cursorMark = this.currenctCursorMark;
    this.solrSearch.searchString = formData.title;
    this.solrSearch.locationString = formData.zip;
    this.solrSearch.searchParams = {};
    this.solrSearch.sort.push({
      sortColumn: this.searchElement.sortColumn,
      sortDirection: this.searchElement.sortDirection,
    });
    if (formData.id) {
      this.solrSearch.searchParams.id = [formData.id];
    } else if (formData.email) {
      this.solrSearch.searchParams.email = [formData.email];
    }
    if (this.solrSearch.locationString) {
      this.updateZipField();
    }
    this.updateDateSearchDto();
    this.searchResults();
  }

  updateZipField() {
    const data = this.solrSearch.locationString.split(',');
    if (data.length === 1) {
      // zip or city or state or statecode
      let zipOrCity = data[0];
      if (this.zipCodeRegex.test(zipOrCity)) {
        // zip
        this.solrSearch.searchParams.postal_code = [zipOrCity];
      } else {
        //state
        if (zipOrCity.length === 2) {
          this.solrSearch.searchParams.state_code = [zipOrCity];
        } else {
          this.solrSearch.searchParams.state = [zipOrCity];
        }
      }
    }
    if (data.length === 2) {
      // city, statecode
      this.solrSearch.searchParams.city_s = [data[0]];
      this.solrSearch.searchParams.state_code = [data[1]];
    }
    if (data.length === 3) {
      // zip, city , statecode
      if (this.zipCodeRegex.test(data[0])) {
        // zip
        this.solrSearch.searchParams.postal_code = [data[0]];
      } else {
        //city
        this.solrSearch.searchParams.city_s = [data[0]];
      }
      this.solrSearch.searchParams.city_s = [data[1]];
      this.solrSearch.searchParams.state_code = [data[2]];
    }
  }

  updateDateSearchDto() {
    const byFromDate = this.searchFormGroup.value.fromDate;
    const byToDate = this.searchFormGroup.value.toDate;
    if (byFromDate) {
      this.dateSearchDTO.fieldName = this.fieldName;

      let fromDate = new Date(byFromDate);
      fromDate.setHours(0, 0, 0, 0);

      this.dateSearchDTO.from = fromDate.toISOString();
      this.solrSearch.dateSearchDTO = this.dateSearchDTO;
    }

    if (byToDate) {
      this.dateSearchDTO.fieldName = this.fieldName;

      let toDate = byToDate;
      toDate.setHours(23, 59, 59, 999);

      this.dateSearchDTO.to = toDate.toISOString();
      this.solrSearch.dateSearchDTO = this.dateSearchDTO;
    } else if (
      byFromDate !== null &&
      byFromDate !== '' &&
      byFromDate !== undefined
    ) {
      this.dateSearchDTO.fieldName = this.searchElement.dateSearchDTOField;

      let toDate = new Date(byFromDate);
      toDate.setHours(23, 59, 59, 999);

      this.dateSearchDTO.to = toDate.toISOString();
      this.solrSearch.dateSearchDTO = this.dateSearchDTO;
    }
  }

  searchResults() {
    this.spinnerService.start();
    this.solrSearch.pageMarks = ['*'];
    this.solrSearchService
      .getResults(this.searchElement.searchApi, this.solrSearch)
      .subscribe((response) => {
        window.scroll(0, 0);
        localStorage.setItem(
          'storePagingParams',
          JSON.stringify(this.solrSearch)
        );
        this.spinnerService.stop();
        this.searchResultEvent.emit({
          responseData: response.data,
          solrSearch: this.solrSearch,
        });
      }, error => {
        this.spinnerService.stop();
      });
  }

  onClearResult() {
    this.searchFormGroup.reset();
    this.clearResultEvent.emit();
  }

  get isValidForm(): boolean {
    return Object.values(this.searchFormGroup.controls).some(
      (control) => control.value
    );
  }

  getCalcColCss(): string {
    let cssClass = '';
    switch (this.elements.length) {
      case 1:
        cssClass = 'col-12 col-md-4 offset-md-3';
        break;
      case 2:
        cssClass = 'col-12 col-md-6 offset-md-2';
        break;
      case 3:
        cssClass = 'col-12 col-md-8 offset-md-1';
        break;
      case 4:
        cssClass = 'col-12 col-md-4 offset-md-3';
        break;
      case 5:
        cssClass = 'col-10';
        break;
    }

    return cssClass;
  }
}
