import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  TemplateRef,
} from '@angular/core';
import {
  SolrSearch,
  SortDirection,
  SortOption,
} from 'src/app/models/solrSearch';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnChanges {
  @Input() templateFromParent: TemplateRef<any>;
  @Input() responseData: any;
  @Input() type: string;
  @Input() pagination: {
    nextMark: string;
    totalRecords: number;
    pageCount: number;
  };

  @Input() solrSearch: SolrSearch;
  @Input() sortOptions: SortOption[];

  start: number = 1;
  end: number = 0;

  totalViewedRecords: number;
  pageSize: number;

  pageMarks: string[] = ['*'];
  currentPageCount: number = 0;
  currenctCursorMark = '*';

  @Output() searchResultEvent = new EventEmitter<SolrSearch>();

  ngOnChanges() {
    this.currenctCursorMark = this.pagination?.nextMark;
    this.pageSize = this.solrSearch?.pageSize;
    this.pageMarks = this.solrSearch?.pageMarks;
    if (this.pageMarks?.length === 1) {
      this.start = 1;
      this.end = this.solrSearch?.pageSize;
    }
    if (
      this.totalViewedRecords > this.pagination?.totalRecords ||
      this.pagination?.totalRecords <= this.pageSize
    ) {
      this.totalViewedRecords = this.pagination.totalRecords;
      this.end = this.pagination.totalRecords;
    }
  }

  ngOnInit() {
    this.totalViewedRecords = this.pageSize;
  }

  getSortIcon(option: SortOption) {
    if (option.sortDirection === SortDirection.None) {
      return '';
    }
    option.isActive = true;
    return option.sortDirection === SortDirection.Ascending
      ? 'fa fa-angle-up'
      : 'fa fa-angle-down';
  }

  onSortBy(option: SortOption) {
    //reset all sort
    this.sortOptions.forEach((opt) => {
      if (opt.displayName !== option.displayName) {
        opt.isActive = false;
        opt.sortDirection = SortDirection.None;
      }
    });

    if (option.sortDirection === '') {
      option.sortDirection = SortDirection.Ascending;
    } else {
      option.sortDirection =
        option.sortDirection === SortDirection.Ascending
          ? SortDirection.Descending
          : SortDirection.Ascending;
    }
    option.isActive = true;

    let sortColumns = option.sortKey.split(',');
    this.solrSearch.sort = [
      { sortColumn: sortColumns, sortDirection: option.sortDirection },
    ];
    this.solrSearch.cursorMark = '*';
    this.pageMarks = [];
    this.pageMarks.push('*');
    this.solrSearch.pageMarks = this.pageMarks;
    this.currentPageCount = 0;
    this.start = 1;
    this.totalViewedRecords = this.pageSize;
    this.searchResultEvent.emit(this.solrSearch);
  }

  onPageSelected($event: Event, b: boolean) {
    this.pageSize = +$event.target['value'];
    this.totalViewedRecords = this.pageSize;
    this.solrSearch.pageSize = +this.pageSize;
    this.solrSearch.cursorMark = '*';
    this.pageMarks = [];
    this.pageMarks.push('*');
    this.solrSearch.pageMarks = this.pageMarks;
    this.currentPageCount = 0;
    this.start = 1;
    this.searchResultEvent.emit(this.solrSearch);
  }

  onShowPreviousPage() {
    if (this.currentPageCount > 0) {
      if (this.totalViewedRecords === this.pagination.totalRecords) {
        this.totalViewedRecords =
          this.currentPageCount * this.solrSearch.pageSize;
        this.end = this.currentPageCount * this.pageSize;
      } else {
        this.totalViewedRecords =
          this.totalViewedRecords - this.solrSearch.pageSize;
        this.end = this.end - this.pageSize;
      }
      this.start = this.start - this.pageSize;
      this.currentPageCount = this.currentPageCount - 1;
      this.currenctCursorMark = this.pageMarks[this.currentPageCount];
      if (this.currenctCursorMark === '*') {
        this.pageMarks = [];
        this.pageMarks.push('*');
      } else {
        this.pageMarks.pop();
      }
    } else {
      this.solrSearch.cursorMark = this.currenctCursorMark;
      this.totalViewedRecords = this.solrSearch.pageSize;
    }
    this.solrSearch.pageMarks = this.pageMarks;
    this.solrSearch.cursorMark = this.currenctCursorMark;
    this.searchResultEvent.emit(this.solrSearch);
    window.scrollTo(0, 0);
  }

  onShowNextPage() {
    this.totalViewedRecords =
      this.totalViewedRecords + this.solrSearch.pageSize;
    this.start = this.start + this.pageSize;
    this.currentPageCount = this.currentPageCount + 1;
    this.end = this.end + this.pageSize;
    this.pageMarks.push(this.currenctCursorMark);
    this.solrSearch.cursorMark = this.currenctCursorMark;
    this.solrSearch.pageMarks = this.pageMarks;
    this.searchResultEvent.emit(this.solrSearch);
    window.scrollTo(0, 0);
  }
}
